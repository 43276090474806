'use strict';

import { ServerConstants } from '../../../../../../core/serverconstants';

export class ActivityInstinctSurveyIdController {
  static $inject = ['serverConstants', '$scope'];
  constructor(
    private serverConstants: ServerConstants,
    private $scope: ng.IScope) {
    this.validationConstants = this.serverConstants.validationConstants;
  }
  model;
  validationConstants;

  $onInit() {
    // SurveyId is a string by default; regex ensures only numbers are allowed. Using type="number"
    // would cause Angular errors when strings are provided.
    this.$scope.$watch('vm.model.SurveyId', () => {
      if (this.model.SurveyId && !this.validationConstants.digitRegex.test(this.model.SurveyId)) {
        this.model.SurveyId = this.model.SurveyId.replace(/\D/g, ''); // Remove non-numeric values
      }
    });
  }
}
