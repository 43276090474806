'use strict';

import { ServerConstants } from './../../core/serverconstants';
import { AuthService } from '../../core/dataservices/auth.service';
import { ClientService } from 'src/app/core/dataservices/client.service';
import { SelectedClientFactory } from 'src/app/core/selectedclient.factory';
import { IRelatedClientInfo } from 'src/app/core/dataservices/dataservice.contracts';

export class ResearcherLoginController {
  static $inject = ['authService', '$stateParams', 'serverConstants', 'clientservice', 'selectedClientFactory'];

  constructor(
    private authService: AuthService,
    private $stateParams: ng.ui.IStateParamsService,
    private serverConstants: ServerConstants,
    private clientservice: ClientService,
    private selectedClientFactory: SelectedClientFactory,
  ) { }

  async $onInit() {
    // setup selected client factory properly first
    await this.setupSelectedClientFactory();

    const switchInterface = this.$stateParams.switchinterface;
    const activateMessage = this.$stateParams.activatemsg && this.$stateParams.activatemsg === 'true';
    const redirectType: number = this.$stateParams.redirectType ?
      Number(this.$stateParams.redirectType) :
      this.serverConstants.researcherLoginRedirectTypeConstants.activities;
    const additionalParams = {
      activityGuid: this.$stateParams.activityGuid,
      step: this.$stateParams.step,
      replyGuid: this.$stateParams.replyGuid,
      isEdit: this.$stateParams.isEdit,
      activateMessage,
    };

    await this.authService.redirectToRoute(redirectType, switchInterface, additionalParams);
  }

  async setupSelectedClientFactory() {
    const response = await this.clientservice.getClientDetails(this.$stateParams.clientGuid);
    if (response?.Detail) {
      const client = response.Detail;
      this.selectedClientFactory.setClientInfo({
        Id: client.Id,
        Name: client.AdminName || client.Name,
        Guid: client.Guid,
        VideoStorage: client.VideoStorage,
        Code: client.Code,
        RelatedClients: client.RelatedClients as IRelatedClientInfo[],
      });
    }
  }
}
