'use strict';

import { DateTime } from 'luxon';
import { ActivityService } from './../../../../core/dataservices/activity.service';
import { DateFormatService } from './../../../../core/services/dateformat.service';
import { SelectedSquareFactory } from './../../../../core/selectedsquare.factory';
import { ServerConstants } from '../../../../core/serverconstants';
import { equals, IHttpPromise } from 'angular';
import { FeatureService } from '../../../../core/dataservices/feature.service';
import { SquareActivityModel } from '../../squareActivityModel';
import { PoboService } from '../../../../core/services/pobo.service';
import { SegmentationService } from '../../../../core/dataservices/segmentation.service';
import { Criteria } from './Criteria';
import { ActivitySpecifyModel } from './ActivitySpecifyModel';
import { IscUIUtils } from 'isc-ui';
import { DiscussionDataService } from 'src/app/core/services/discussionData.service';
import { ActivityHelperService } from 'src/app/core/services/activityHelper.service';
import { ActivityQualSpecifyControllerBase } from '../common/activityQualSpecifyControllerBase';
import { Dictionary } from 'lodash';

export class ActivityQualSpecifyController extends ActivityQualSpecifyControllerBase {
  static $inject = [
    '$mdDialog', '$stateParams', 'activityservice', 'serverConstants',
    'dateFormatService', '_', '$q', 'logger', 'selectedSquareFactory',
    'featureservice', 'poboService', 'segmentationService', 'discussionDataService',
    'activityHelperService',
  ];

  constructor(
    protected $mdDialog: ng.material.IDialogService,
    private $stateParams: ng.ui.IStateParamsService,
    private activityservice: ActivityService,
    serverConstants: ServerConstants,
    dateFormatService: DateFormatService,
    private _: _.LoDashStatic,
    private $q: ng.IQService,
    private logger: Logger,
    private selectedSquareFactory: SelectedSquareFactory,
    private featureService: FeatureService,
    private poboService: PoboService,
    private segmentationService: SegmentationService,
    private discussionDataService: DiscussionDataService,
    private activityHelperService: ActivityHelperService,
  ) {
    super($mdDialog, dateFormatService, serverConstants);
    this.validationConstants = serverConstants.validationConstants;
    this.activityTypes = serverConstants.squareActivityTypeConstants;
    this.activityContextTypeConstants = serverConstants.activityContextTypeConstants;
  }

  disableCompleteCriterias = false;
  validationConstants;
  activityTypes;
  activityContextTypeConstants;
  isDraftOrScheduled = false;
  removeLink;
  minStartDate;
  minEndDate;
  minVisibilityConditionDate: DateTime;
  maxVisibilityConditionDate: DateTime;
  squareLanguage;
  allowStartDateEdit = false;
  allowEndDateEdit = true;
  currentTimeZone = DateTime.local().offset / 60;
  wizardStep;
  squareActivity: SquareActivityModel;
  form;
  model: ActivitySpecifyModel;
  initialStatus;
  initialContributionType?: number = null;
  initialContext?: number = null;
  initialModeratorCuration: boolean = false;
  isPublished;
  isActivityTargetCompletionEnabled;
  timespan = {
    isValid: true,
    text: '',
  };

  originalCompleteCriterias = undefined;
  originalVisibility = undefined;

  uninfluencedPlaceHolder = this.serverConstants.activityVisibilityTypeConstants.uninfluenced;

  CompleteCriteria = [
    new Criteria('photo(s)', this.serverConstants.activityQualCompleteCriteriaTypeConstants.image, 1),
    new Criteria('video(s)', this.serverConstants.activityQualCompleteCriteriaTypeConstants.video, 1),
    new Criteria('online media', this.serverConstants.activityQualCompleteCriteriaTypeConstants.onlineMedia, 1),
    new Criteria('attachment(s)', this.serverConstants.activityQualCompleteCriteriaTypeConstants.attachment, 1),
  ];
  isReadOnly;
  saveNotApplicable = false;
  isSavingUpReward;
  curationFeedbackCircleEnabled = false;
  saveCallback = () => this.saveData();
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  customValidation = () => this.validateNavigation('', '', this);
  resetFormCallback = () => this.resetForm();
  usesArchivedSegmentations: boolean;
  isVisibilityConditionTimeChecked: boolean = false;

  validatecompleteCriteriaValue(completeCriteria) {
    if (completeCriteria && completeCriteria.Value && completeCriteria.Value === parseInt(completeCriteria.Value, 10) && completeCriteria.Value > 0) {
      return true;
    }
    return false;
  }

  onContextChange(value: number) {
    switch (value) {
      case this.serverConstants.activityContextTypeConstants.public:
        this.model.Visibility = this.Visibility[1].Value;
        if (this.isDraftOrScheduled) {
          this.model.ContributionType = this.serverConstants.activityContributionTypeConstants.standard;
        }
        break;
      case this.serverConstants.activityContextTypeConstants.private:
        this.model.Visibility = this.serverConstants.activityVisibilityTypeConstants.private;
        if (this.isDraftOrScheduled) {
          this.model.ContributionType = this.serverConstants.activityContributionTypeConstants.diary;
        }
        break;
    }
    this.form.Context.$setValidity('serverErrors', true);
  }

  getLanguageWordsCompositionKind(lang): number {
    const wordsOfOneLetterLanguages = this.serverConstants.validationConstants.wordsOfOneLetterLanguages.split(',');
    if (this._.includes(wordsOfOneLetterLanguages, lang)) {
      return this.serverConstants.languageWordsCompositionKindConstants.wordsOfOneLetter;
    }
    return this.serverConstants.languageWordsCompositionKindConstants.wordsOfMoreLetters;
  }

  async $onInit() {
    this.isDraftOrScheduled = this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.scheduled
      || this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft;
    if (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.qualitativeResearch) {
      const squareLanguage = await this.selectedSquareFactory.languagePromise;
      switch (this.getLanguageWordsCompositionKind(squareLanguage)) {
        case this.serverConstants.languageWordsCompositionKindConstants.wordsOfOneLetter:
          this.CompleteCriteria.unshift(new Criteria('character(s)', this.serverConstants.activityQualCompleteCriteriaTypeConstants.word, 50));
          break;
        default:
          this.CompleteCriteria.unshift(new Criteria('word(s)', this.serverConstants.activityQualCompleteCriteriaTypeConstants.word, 50));
          break;
      }
    }

    this.isActivityTargetCompletionEnabled = await this.featureService.checkFeatureAccessibilityForSetup(
      this.serverConstants.featureConstants.activityTargetCompletion);

    // CurationFeedbackCircle here is enabled only if is enabled in master
    this.curationFeedbackCircleEnabled = await this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.curationFeedbackCircle);

    this.removeLink = this.wizardStep.linkComponent('specifyQual', this);
    this.resetForm();
    this.allowStartDateEdit = this.squareActivity.Detail.CanChangeStartDate;
    this.disableCompleteCriterias = !this.allowStartDateEdit;
    this.initialStatus = angular.copy(this.squareActivity.Detail.Status);
    this.saveNotApplicable = this.squareActivity.ActivityQualDetail ? this.squareActivity.ActivityQualDetail.IsPublished : false;
    this.model.StartTime = this.model.StartDateTime;
    this.model.EndTime = this.model.EndDateTime;
    this.model.VisibilityConditionTime = this.model.VisibilityConditionDate;

    if (this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.closed) {
      const segmentations = await this.segmentationService
        .getSquareSegmentations(true, true, true, this.squareActivity.Detail.Guid);
      this.usesArchivedSegmentations = this._.some(segmentations, (e) => e.IsArchived);
    }
  }

  private initDividedDiscussionProperties() {
    this.model.Visibility =
      this.originalVisibility =
      this.squareActivity.ActivityQualDetail.Visibility;

    if (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.qualitativeResearch) {
      this.model.Context = this.squareActivity.ActivityQualDetail.Context;
    } else {
      this.model.Context = this.model.Visibility === this.serverConstants.activityVisibilityTypeConstants.private
        ? this.serverConstants.activityContextTypeConstants.private
        : this.serverConstants.activityContextTypeConstants.public;
    }

    this.initialModeratorCuration = angular.copy(this.squareActivity.ActivityQualDetail.ModeratorCuration);
    this.model.ModeratorCuration = this.squareActivity.ActivityQualDetail.ModeratorCuration;

    this.model.ContributionType = this.squareActivity.ActivityQualDetail.ContributionType;
    /* In case the activity is already created with a ContributionType (standard/diary) and the curationFeedbackCircle feature is disabled after that moment,
    we still need to show all the fields related to the ContributionType related to that activity */
    this.initialContributionType = angular.copy(this.squareActivity.ActivityQualDetail.ContributionType);
    this.initialContext = this.model.Context;

    this.model.VisibilityConditionType = this.squareActivity.ActivityQualDetail.VisibilityConditionType;

    if (this.squareActivity.ActivityQualDetail.VisibilityConditionBufferHours !== undefined &&
      this.squareActivity.ActivityQualDetail.VisibilityConditionBufferHours !== null) {
      this.model.VisibilityConditionBufferHours = this.squareActivity.ActivityQualDetail.VisibilityConditionBufferHours;
      this.maxVisibilityConditionDate = this.model.VisibilityConditionBufferHours !== undefined && this.model.VisibilityConditionBufferHours !== null
        ? this.model.EndDateTime.minus({ hours: this.model.VisibilityConditionBufferHours })
        : this.model.EndDateTime;
      this.isVisibilityConditionTimeChecked = true;
      angular.element(() => {
        // Elements loaded on the page
        this.form.VisibilityConditionDate.$setValidity('required', this.model.VisibilityConditionDate !== undefined && this.model.VisibilityConditionDate !== null);
        this.form.VisibilityConditionTime.$setValidity('required', this.model.VisibilityConditionDate !== undefined && this.model.VisibilityConditionDate !== null);
      });
    } else if (this.curationFeedbackCircleEnabled && this.model.Visibility === this.serverConstants.activityVisibilityTypeConstants.uninfluenced) {
      this.model.VisibilityConditionBufferHours = this.serverConstants.squareConstants.defaultActivityVisibilityConditionBufferHours;
    }

    if (this.squareActivity.ActivityQualDetail.VisibilityConditionDate) {
      this.model.VisibilityConditionDate = this.dateFormatService.getDateTime(this.squareActivity.ActivityQualDetail.VisibilityConditionDate, this.model.DateOffset);
    }
  }

  private setEndTimeInit() {
    if (this.squareActivity.Detail.EndDate) {
      this.model.EndDateTime = this.dateFormatService.getDateTime(this.squareActivity.Detail.EndDate, this.model.DateOffset);
    }
  }

  private setCriteriasInit() {
    if (this.squareActivity.CompleteCriterias) {
      for (const squareActivityCompleteCriteria of this.squareActivity.CompleteCriterias) {
        for (const completeCriteria of this.CompleteCriteria) {
          if (squareActivityCompleteCriteria.CriteriaType === completeCriteria.CriteriaType) {
            completeCriteria.Value = squareActivityCompleteCriteria.Value;
            completeCriteria.Checked = true;
          }
        }
      }
    }
    this.originalCompleteCriterias = angular.copy(this.CompleteCriteria);
  }

  private setThemeInit() {
    if (this.squareActivity.Detail.ThemeGuid) {
      this.model.Theme = this.squareActivity.Detail.ThemeGuid;
    }
  }

  private populateModelDefaultTimesInit() {
    this.minStartDate = this.dateFormatService.startOfDay();
    this.minEndDate = DateTime.fromISO(this.minStartDate);
    const defaultEndDate = this.squareActivity.Detail.EndDate ? DateTime.fromISO(this.squareActivity.Detail.EndDate) : this.minEndDate.plus({ days: 7 });
    this.maxVisibilityConditionDate = defaultEndDate;
    this.model = new ActivitySpecifyModel(DateTime.now(), defaultEndDate, this.currentTimeZone, () => this.onTimeZoneChanged());
  }

  private mapDatesInit() {
    if (this.squareActivity.Detail.Offset) {
      this.model.DateOffset = this.squareActivity.Detail.Offset;
    }
    this.model.StartDateTime = this.dateFormatService.getDateTime(this.squareActivity.Detail.StartDate, this.model.DateOffset);
    this.minStartDate = this.dateFormatService.getMinDate(this.minStartDate, this.model.StartDateTime);
    this.minEndDate = this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.closed ?
      DateTime.now() :
      this.minEndDate || this.minStartDate;

    this.minVisibilityConditionDate = this.model.StartDateTime;
  }

  $wizardStepIsCompleted() {
    return this.squareActivity !== undefined && this.squareActivity.ActivityQualDetail !== undefined;
  }

  $wizardIsValid() {
    // Make CompletionTarget empty when the checkbox is unticked
    this.model.CompletionTarget = this.model.IsCompletionTarget ? this.model.CompletionTarget : null;
    if (!this.isActivityTargetCompletionEnabled && this.model.IsCompletionTarget && (this.model.CompletionTarget == null ||
      this.model.CompletionTarget < 0 ||
      this.model.CompletionTarget > this.serverConstants.validationConstants.integerMaxValue)) {
      return false;
    }

    if (this.isSavingUpReward && this.model.CompletionPoints
      && ((this.model.CompletionPoints.Qualified < 0 || this.model.CompletionPoints.Qualified > this.serverConstants.validationConstants.integerMaxValue)
        || (this.model.CompletionPoints.QuotaFull < 0 || this.model.CompletionPoints.QuotaFull > this.serverConstants.validationConstants.integerMaxValue)
        || (this.model.CompletionPoints.ScreenedOut < 0 || this.model.CompletionPoints.ScreenedOut > this.serverConstants.validationConstants.integerMaxValue))) {
      return false;
    }

    if (this.squareActivity.Detail.ActivityType !== this.serverConstants.squareActivityTypeConstants.qualitativeResearch) {
      return this.form.$valid;
    }
    if (this._.filter(this.CompleteCriteria, (cc) => cc.Checked === true).length === 0) {
      return false;
    }
    let minimOneCompleteCriteria = false;
    for (const completeCriteria of this.CompleteCriteria) {
      if (this.validatecompleteCriteriaValue(completeCriteria)) {
        minimOneCompleteCriteria = true;
        break;
      }
    }

    return this.form.$valid && minimOneCompleteCriteria;
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    let description = '';
    if (this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft) {
      description = this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }
    return description;
  }

  calculateTimespanValidity() {
    if (this.model.StartDateTime && this.model.EndDateTime) {
      const end = this.model.EndDateTime.startOf('minute');
      const start = this.model.StartDateTime.startOf('minute');
      const min = end.diff(start).as('minutes');
      if (min < 0) {
        this.timespan = {
          isValid: false,
          text: 'End date ends before start date.',
        };
      } else if (min === 0) {
        this.timespan = {
          isValid: false,
          text: 'The start and end time are the same.',
        };
      } else {
        // The reason to also include 'minutes' in the diff method is to make sure that hours is a whole number
        const { days, hours } = end.diff(start, ['days', 'hours', 'minutes']).toObject();
        this.timespan = {
          isValid: true,
          text: `${(days > 0 ? (`${days} days and `) : '') + hours} hours.`,
        };
      }
    }
  }

  onContributionTypeChange() {
    // This.model.ContributionType holds the value before the change takes effect
    if (this.model.ContributionType !== this.serverConstants.activityContributionTypeConstants.standard) {
      // the New Value is activityContributionTypeConstants.standard
      this.model.ModeratorCuration = false;
      this.model.VisibilityConditionType = this.serverConstants.activityVisibilityConditionTypeConstants.default;
    } else if (this.model.Visibility === this.serverConstants.activityVisibilityTypeConstants.uninfluenced) {
      // the New Value is activityContributionTypeConstants.diary
      this.isVisibilityConditionTimeChecked = true;

      // The Visibility Condition date and time fields should be required when the contribution type is diary (multiple contributions)
      angular.element(() => {
        // Elements loaded on the page
        this.form.VisibilityConditionDate
          .$setValidity('required', this.model.VisibilityConditionDate !== undefined && this.model.VisibilityConditionDate !== null);
        this.form.VisibilityConditionTime
          .$setValidity('required', this.model.VisibilityConditionDate !== undefined && this.model.VisibilityConditionDate !== null);
      });
    }
  }

  onVisibilityChange(newValue: number) {
    if (newValue === this.serverConstants.activityVisibilityTypeConstants.influenced &&
      (this.curationFeedbackCircleEnabled || this.initialContributionType)) {
      this.model.ContributionType = this.serverConstants.activityContributionTypeConstants.standard;
      this.model.ModeratorCuration = false;
      this.model.VisibilityConditionType = null;
      this.model.VisibilityConditionBufferHours = null;
      this.model.VisibilityConditionDate = null;

      return;
    }

    if (newValue !== this.serverConstants.activityVisibilityTypeConstants.uninfluenced) {
      return;
    }

    this.model.ContributionType = this.serverConstants.activityContributionTypeConstants.standard; // Set default value

    if (!this.curationFeedbackCircleEnabled) {
      return;
    }

    this.model.VisibilityConditionType = this.serverConstants.activityVisibilityConditionTypeConstants.default;
    this.model.VisibilityConditionBufferHours = this.serverConstants.squareConstants.defaultActivityVisibilityConditionBufferHours;
    this.maxVisibilityConditionDate = this.maxVisibilityConditionDate.minus({ hours: this.model.VisibilityConditionBufferHours });
    this.isVisibilityConditionTimeChecked = false;

    if (this.model.ContributionType === this.serverConstants.activityContributionTypeConstants.diary) {
      // The Visibility Condition date and time fields should be required when the contribution type is diary (multiple contributions)
      angular.element(() => {
        // Elements loaded on the page
        this.form.VisibilityConditionDate
          .$setValidity('required', this.model.VisibilityConditionDate !== undefined && this.model.VisibilityConditionDate !== null);
        this.form.VisibilityConditionTime
          .$setValidity('required', this.model.VisibilityConditionDate !== undefined && this.model.VisibilityConditionDate !== null);
      });
    }
  }

  onStartDateChanged() {
    if (this.model.StartTime) {
      this.model.StartDateTime = this.model.StartDateTime.set({ hour: this.model.StartTime.hour, minute: this.model.StartTime.minute });
      this.model.StartTime = this.model.StartDateTime;
    }
    this.form.StartDate.$setValidity('serverErrors', true);
    this.minEndDate = this.dateFormatService.startOfDay(this.model.StartDateTime);
    this.calculateTimespanValidity();
    this.minVisibilityConditionDate = this.model.StartDateTime;
  }

  onEndDateChanged() {
    if (this.model.EndTime) {
      this.model.EndDateTime = this.model.EndDateTime.set({ hour: this.model.EndTime.hour, minute: this.model.EndTime.minute });
      this.model.EndTime = this.model.EndDateTime;
    }
    this.form.EndDate.$setValidity('serverErrors', true);
    this.calculateTimespanValidity();
    this.isReadOnly = false;
    if (this.model.EndDateTime) {
      if (this.model.EndDateTime.diff(DateTime.utc()).as('days') >= 0
        && this.initialStatus === this.serverConstants.squareActivityStatusConstants.closed) {
        this.squareActivity.Detail.Status = this.serverConstants.squareActivityStatusConstants.active;
      } else {
        this.squareActivity.Detail.Status = this.initialStatus;
      }
      this.maxVisibilityConditionDate = this.model.VisibilityConditionBufferHours !== undefined && this.model.VisibilityConditionBufferHours !== null
        ? this.model.EndDateTime.minus({ hours: this.model.VisibilityConditionBufferHours })
        : this.model.EndDateTime;

      if (this.maxVisibilityConditionDate < this.model.VisibilityConditionDate) {
        this.model.VisibilityConditionDate = this.maxVisibilityConditionDate;
      }
    }
  }

  onTimeZoneChanged() {
    this.calculateTimespanValidity();
  }

  onVisibilityConditionTimeCheckedChange() {
    this.form.VisibilityConditionDate.$setValidity('required', this.isVisibilityConditionTimeChecked);
    this.form.VisibilityConditionTime.$setValidity('required', this.isVisibilityConditionTimeChecked);
  }

  onVisibilityConditionBufferHoursChanged(newValue: number) {
    this.form.VisibilityConditionBufferHours.$setValidity('serverErrors', true);
    this.form.VisibilityConditionDate.$setValidity('serverErrors', true);
    this.maxVisibilityConditionDate = this.model.EndDateTime.minus({ hours: newValue });
    if (this.maxVisibilityConditionDate < this.model.VisibilityConditionDate) {
      this.model.VisibilityConditionDate = this.maxVisibilityConditionDate;
    }
  }

  onVisibilityConditionDateChanged() {
    if (this.model.VisibilityConditionTime) {
      this.model.VisibilityConditionDate = this.model.VisibilityConditionDate
        .set({ hour: this.model.VisibilityConditionTime.hour, minute: this.model.VisibilityConditionTime.minute });
    }
    this.form.VisibilityConditionDate.$setValidity('serverErrors', true);
  }

  async $wizardBeforeNext() {
    let ok = this.squareActivity.Detail.Status !== this.serverConstants.squareActivityStatusConstants.closed &&
      this.squareActivity.Detail.Status !== this.serverConstants.squareActivityStatusConstants.active;
    const visibilityChanged = this.originalVisibility !== undefined && !equals(this.originalVisibility, this.model.Visibility);
    const completeCriteriasChanged = this.disableCompleteCriterias ?
      false :
      this.originalCompleteCriterias && !equals(this.originalCompleteCriterias, this.CompleteCriteria);
    const unarchiveSegmentation = this.usesArchivedSegmentations;
    const jobIdWillBePrefilled = this._.isNull(this.squareActivity.Detail.SquareJobId)
      && this.initialStatus === this.serverConstants.squareActivityStatusConstants.closed
      && this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.active
      && this.isSavingUpReward;
    if (!ok && (completeCriteriasChanged || visibilityChanged || unarchiveSegmentation || jobIdWillBePrefilled)) {
      await this.$mdDialog.show(
        this.$mdDialog.iscConfirm()
          .title('Activity configuration')
          .text(`Changes might impact:\n\n${completeCriteriasChanged ? '    - the activity status for participants and are not communicated automatically\n' : ''
          }${visibilityChanged ? '    - the discussion visibility (please consider to inform the participants about this in the opening post)\n' : ''
          }${unarchiveSegmentation ? '    - reopening this activity will unarchive segmentations used by it\n' : ''
          }${jobIdWillBePrefilled ? '    -  As Saving up is enabled, a JobID needs to be assigned to this activity.' +
              ' By default, this will be updated to the latest JobID available on the Square info page. Please check,' +
              ' and adapt if needed, via Activity info page. ' : ''
          }\nAre you sure you want to proceed?`)
          .ok('Accept changes')
          .cancel('Cancel'),
      ).then(
        () => {
          ok = true;
        });
    } else {
      ok = true;
    }

    if (ok) {
      if (this.squareActivity.ActivityQualDetail?.IsPublished === true) {
        let activityUsername = this.squareActivity.ActivityQualDetail.OpenPostUsername;
        activityUsername = await this.poboService.checkPoboConfirmation(
          this.squareActivity.ActivityQualDetail.OpenPostUsername,
          !this.squareActivity.ActivityQualDetail.Title);
        this.squareActivity.ActivityQualDetail.OpenPostUsername = activityUsername;
      }
      await this.saveWithParentActivityChangesConfirmation();
    }
    this.$q.reject();
  }

  validateNavigation(toParams, fromParams, root) {
    if (!root) {
      root = this;
    }
    return {
      canNavigate: root.form.$valid &&
        (root.squareActivity.Detail.ActivityType !== root.serverConstants.squareActivityTypeConstants.qualitativeResearch ||
          root._.filter(root.CompleteCriteria, (cc) => cc.Checked === true).length > 0),
      isDirty: root.form.$dirty,
    };
  }

  protected getSquareActivity() {
    return this.squareActivity;
  }

  protected getModel() {
    return this.model;
  }

  protected async saveData() {
    const dataToSend = {
      ActivityGuid: this.$stateParams.activityGuid,
      Context: this.model.Context,
      ThemeGuid: this.model.Theme,
      ModeratorCuration: this.model.ModeratorCuration,
      Visibility: this.model.Visibility,
      CompletionPoints: this.model.CompletionPoints,
      StartDate: this.dateFormatService.getDateTimeForSaving(this.model.StartDateTime, this.model.DateOffset),
      EndDate: this.dateFormatService.getDateTimeForSaving(this.model.EndDateTime, this.model.DateOffset),
      CompletionTarget: this.model.IsCompletionTarget ? this.model.CompletionTarget : null,
      CompleteCriterias: this.CompleteCriteria,
      Offset: this.model.DateOffset,
      ContributionType: this.showParticipantContributions ? this.model.ContributionType : 0,
      VisibilityConditionType: this.model.VisibilityConditionType,
      VisibilityConditionBufferHours: this.isVisibilityConditionTimeChecked ? this.model.VisibilityConditionBufferHours : null,
      VisibilityConditionDate: this.isVisibilityConditionTimeChecked ? this.model.VisibilityConditionDate : null,
    };
    const updateActivityQualPromise = this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.qualitativeResearch ?
      this.activityservice.updateActivityQualSpecify(dataToSend) :
      this.activityservice.updateActivityQualSpecifyForDividedTypes(dataToSend);

    // This step is implemented for the activity types that belong to the monolith + the Discussion 2.0 New types (which belong to the new Discussions engine)
    // Depending on the activity type, we need to save the data differently
    if (!IscUIUtils.isActivityDiscussionNewType(this.squareActivity.Detail.ActivityType, this.serverConstants)) {
      return this.saveActivityData(updateActivityQualPromise);
    }
    return await this.saveDiscussionNewData(updateActivityQualPromise);
  }

  protected isReadOnlyActivity(): boolean {
    return this.isReadOnly;
  }

  resetForm() {
    this.populateModelDefaultTimesInit();
    this.mapDatesInit();
    this.setEndTimeInit();
    this.setThemeInit();
    this.calculateTimespanValidity();
    if (!this.squareActivity) {
      return;
    }

    if (this.isSavingUpReward) {
      this.model.CompletionPoints = {
        Qualified: this.squareActivity.Detail.CompletionPoints,
        ScreenedOut: this.squareActivity.Detail.CompletionPointsScreenedOut,
        QuotaFull: this.squareActivity.Detail.CompletionPointsQuotaFull,
      };
    }

    // In drafts, is true by default, but not needed when feature is toggled on
    this.model.IsCompletionTarget = this.squareActivity.Detail.IsCompletionTarget;
    this.model.CompletionTarget = this.squareActivity.Detail.CompletionTarget;

    if (!this.squareActivity.ActivityQualDetail) {
      if (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.qualitativeResearch ||
        this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.discussion20Research) {
        this.model.Context = this.Context[0].Value;
      }
      const isPrivateDiscussion =
        this.activityHelperService.isPrivateDiscussion(this.squareActivity.Detail.ActivityType, this.squareActivity.ActivityQualDetail?.Visibility);
      this.model.Visibility = this.originalVisibility = isPrivateDiscussion
        ? this.serverConstants.activityVisibilityTypeConstants.private
        : this.Visibility[1].Value;

      if (isPrivateDiscussion || this.curationFeedbackCircleEnabled) {
        this.model.ContributionType = this.serverConstants.activityContributionTypeConstants.standard;
      }
    } else {
      if (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.qualitativeResearch) {
        this.setCriteriasInit();
      }
      this.initDividedDiscussionProperties();

      this.form.$setPristine();
    }
  }

  comleteCriteriaChecked(completeCriteria) {
    if (completeCriteria.Checked) {
      if (!completeCriteria.Value) {
        completeCriteria.Value = completeCriteria.DefaultValue;
      }
    } else {
      if (completeCriteria.Value) {
        completeCriteria.DefaultValue = completeCriteria.Value;
      }
      completeCriteria.Value = null;
    }
  }

  comleteCriteriaChanged(completeCriteria, newValue) {
    if (newValue) {
      completeCriteria.Checked = true;
    } else {
      completeCriteria.Checked = false;
    }
  }

  isLowerThanCompletionTargetLimit() {
    return (this.model != null && this.model.CompletionTarget != null
      && ((this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch
        && this.model.CompletionTarget < this.serverConstants.completionTargetConstants.forumDiscussionMin)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch
          && this.model.CompletionTarget < this.serverConstants.completionTargetConstants.privateDiscussionMin)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateScoutResearch
          && this.model.CompletionTarget < this.serverConstants.completionTargetConstants.lifeScenesMin)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicScoutResearch
          && this.model.CompletionTarget < this.serverConstants.completionTargetConstants.lifeSnapshotsMin)));
  }

  isHigherThanCompletionTargetLimit() {
    return (this.model != null && this.model.CompletionTarget != null
      && ((this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch
        && this.model.CompletionTarget > this.serverConstants.completionTargetConstants.forumDiscussionMax)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch
          && this.model.CompletionTarget > this.serverConstants.completionTargetConstants.privateDiscussionMax)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateScoutResearch
          && this.model.CompletionTarget > this.serverConstants.completionTargetConstants.lifeScenesMax)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicScoutResearch
          && this.model.CompletionTarget > this.serverConstants.completionTargetConstants.lifeSnapshotsMax)));
  }

  isDiscussion20New() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.discussion20Research
      || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.observation;
  }

  get showParticipantContributions() {
    if (!this.model || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.qualitativeResearch) {
      return false;
    }

    // Private discussions should always show participant contributions
    const isPrivateDisc = this.activityHelperService.isPrivateDiscussion(this.squareActivity.Detail.ActivityType, this.model.Visibility);
    if (isPrivateDisc) {
      return true;
    }

    if (this.showModeratorCuration) {
      return true;
    }

    const contextChanged = this.initialContext !== this.model?.Context;
    if (!contextChanged) {
      return !!this.initialContributionType;
    }

    return false;
  }

  get showModeratorCuration() {
    if (!this.model ||
      this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.qualitativeResearch ||
      this.model.Visibility === this.serverConstants.activityVisibilityTypeConstants.influenced) {
      return false;
    }
    return this.curationFeedbackCircleEnabled || this.initialModeratorCuration;
  }

  get areVisibilityConditionsVisible() {
    if (this.model) {
      return (this.curationFeedbackCircleEnabled || this.initialContributionType)
        && this.model.Visibility === this.serverConstants.activityVisibilityTypeConstants.uninfluenced
        && this.squareActivity.Detail.ActivityType !== this.serverConstants.squareActivityTypeConstants.qualitativeResearch
        && !this.isDiscussion20New(); // don't show participant contribution options on discussion 2.0 new activities;
    }

    return false;
  }

  get isResearchActivity() {
    return this.activityservice.isQualResearchActivity(this.squareActivity.Detail.ActivityType);
  }

  private saveActivityData(updateActivityQualPromise: IHttpPromise<any>) {
    return updateActivityQualPromise
      .then((response) => {
        this._.merge(this.squareActivity.ActivityQualDetail, response.data.ActivityQual);
        this.squareActivity.CompleteCriterias = response.data.CompleteCriterias;
        this.form.$setPristine();
        this.$stateParams.activityQualGuid = response.data.ActivityQual.Guid;
        this.originalVisibility = this.model.Visibility;
        this.originalCompleteCriterias = angular.copy(this.CompleteCriteria);
        this.logger.success('Activity specify successfully updated');
      },
      (error) => this.handleErrorOnSave(error));
  }

  private async saveDiscussionNewData(updateActivityQualPromise: IHttpPromise<any>) {
    try {
      await updateActivityQualPromise;
      // we also need to call endpoints in the Discussions module in order to save everything related to Discussion and DiscussionActivity entities
      const discussionGuid = await this.discussionDataService.createOrUpdateDiscussionActivity(
        this.squareActivity.discussionModel?.discussionGuid,
        this.$stateParams.activityGuid,
        this.model.Visibility,
        this.showParticipantContributions ? this.model.ContributionType : 0,
        this.squareActivity.discussionModel?.title ?? this.squareActivity.Detail.ActivityName,
        this.squareActivity.discussionModel?.isOneByOne);

      // since the flow is somewhat different for new discussions and only at this point have we created the minimum DB objects we reload the model
      // we also need to do this before setting the discussionModel below since that is not part of the backend response
      if (this.squareActivity.ActivityQualDetail == null) {
        const response = await this.activityservice.selectActivityQualResearch(this.$stateParams.activityGuid);
        this.squareActivity = response.data;
      }

      if (!this.squareActivity.discussionModel) {
        this.squareActivity.discussionModel = {
          discussionGuid,
          activityGuid: this.$stateParams.activityGuid,
          contributionType: this.model.ContributionType,
          visibilityType: this.model.Visibility,
          appearance: this.serverConstants.appearanceConstants.centered,
          post: null,
          isOneByOne: false,
          title: this.squareActivity.Detail.ActivityName,
          isAnswered: false,
          caption: '',
        };
      }
      // update the properties that were saved in the Observation specify step
      this.squareActivity.discussionModel.contributionType = this.model.ContributionType;
      this.squareActivity.ActivityQualDetail.ContributionType = this.model.ContributionType;
      this.squareActivity.discussionModel.visibilityType = this.model.Visibility;
      this.squareActivity.ActivityQualDetail.Visibility = this.model.Visibility;

      this.form.$setPristine();
      this.originalVisibility = this.model.Visibility;
      this.logger.success('Activity specifications successfully updated');
    } catch (error) {
      return this.handleErrorOnSave(error);
    }
  }

  private async handleErrorOnSave(error) {
    if (error.status === 400) {
      const errorData = error.data;
      // Group by property name in case there is more than 1 error for that property
      // Ideally we should already group them in the backend
      const grouped = this._.groupBy(errorData.ValidationErrors, 'PropertyName');
      await this.handleActivityWithDisabledTypeCannotBeReopenedErrorMessage(grouped);
      this._.forEach(grouped,
        (item, key) => {
          let message = '';
          this._.forEach(item,
            (errorMessage) => {
              const errorItem: any = errorMessage;
              message += `${errorItem.ErrorMessage} `;
            });
          try {
            this.form[key].$setDirty();
            this.form[key].$setValidity('serverErrors', false);
            this.form[key].errorMessage = message;
          } catch (e) {
            this.logger.error('Activity specifications could not be saved');
          }
        });
    }
    return this.$q.reject(error);
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * Checks if the ActivityWithDisabledTypeCannotBeReopened error is present.
   * If present it will remove all error messages regarding the end date
   * and show a dialog box informing the user about this error.
   * It will also reset the end date field back to its original value.
   */
  private async handleActivityWithDisabledTypeCannotBeReopenedErrorMessage(
    groupedErrors: Dictionary<Array<{ ErrorMessage: string; PropertyName: string }>>) {
    // Check for error
    if (!groupedErrors.EndDate?.length ||
      groupedErrors.EndDate?.findIndex((e) => e.ErrorMessage === this.serverConstants.validationConstants.activityWithDisabledTypeCannotBeReopened) === -1) {
      return;
    }

    // Remove all error messages for EndDate
    delete groupedErrors.EndDate; // Make use of delete to make sure that the end date field is not marked as invalid

    // Show dialog box with error
    await this.$mdDialog.show(
      this.$mdDialog.iscConfirm()
        .text(`${this.serverConstants.validationConstants.activityWithDisabledTypeCannotBeReopened}
The end date will be reset to its original value.`)
        .ok('Ok'),
    );

    // Reset end date field
    const originalDate = this.dateFormatService.getDateTime(this.squareActivity.Detail.EndDate, this.model.DateOffset);
    this.model.EndDateTime = this.model.EndTime = originalDate;
  }
}
