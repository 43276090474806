'use strict';

import { ITargeting, ITargetingItemResponse, IMembersInSegmentsResponse, IMembersCountByTargeting } from './../../core/contracts/targeting.contracts';
import { ServerConstants } from '../../core/serverconstants';
import * as angular from 'angular';
import { TargetService } from '../../core/dataservices/target.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { ParentActivityChangeConfirm } from '../../activity/common/ParentActivityChangeConfirm';
import { SequencedActivityInfoMessageHelper } from '../../square/squareCommunication/communicationWizard/sequencedActivity/sequencedActivityInfoMessageHelper';
import { PoboService } from '../../core/services/pobo.service';
import _ = require('lodash');
import { FeatureService } from '../../core/dataservices/feature.service';
import { DateTime } from 'luxon';
import { ITargetingNumbersUpdated } from '../../core/contracts/activity.contract';
import { NotificationsService } from '../../core/services/notifications';
import { ActivityInfoForSegmentTargetCompletion } from './target-completion/target-completion.controller';

export class WizardTargetingController extends ParentActivityChangeConfirm {
  static $inject = ['segmentationservice', 'spinnerservice',
    '$stateParams', '$mdDialog', 'targetservice', 'logger',
    'serverConstants', '$scope', 'poboService', 'featureservice',
    'notifications', '$q'];
  constructor(
    private segmentationservice,
    private spinnerservice: SpinnerService,
    private $stateParams: ng.ui.IStateParamsService,
    $mdDialog: ng.material.IDialogService,
    private targetService: TargetService,
    private logger: Logger,
    private serverConstants: ServerConstants,
    private $scope: ng.IScope,
    private poboService: PoboService,
    private featureService: FeatureService,
    private notifications: NotificationsService,
    private $q: ng.IQService,
  ) {
    super($mdDialog);
  }

  private targetingNumbersUpdatedStatusSubscription = _.noop;

  squareActivityStatusConstants = this.serverConstants.squareActivityStatusConstants;
  integerMaxValue = this.serverConstants.validationConstants.integerMaxValue;
  isCompleted: boolean;
  form: ng.IFormController;
  removeLink;
  wizardStep;
  segmentationsLength;
  membersByTarget = {};
  _targetingFilterType;
  targeting: ITargeting;
  randomlySelectedFromActivity;
  minimumRandomlySelected = 0;
  matches;
  initialTargeting;
  wizard;
  memberIdsInputText = '';
  loading = true;
  isRecalculatingTargeting = false;
  isRandomizationAllowed = false;
  isRandomizationSelected = false;
  isExcludeParticipantsInvitedSinceWeeksSelected = false;
  squareGuid;
  GlobalTargetingFilterOptions = [
    { Id: 1, Name: 'Match none of the following' },
    { Id: 2, Name: 'Match any of the following' },
    { Id: 3, Name: 'Match all of the following', sign: true },
  ];
  ContainsTargetingOptions = [
    { Id: 1, Name: 'none of the following' },
    { Id: 2, Name: 'any of the following' },
    { Id: 3, Name: 'all of the following', sign: true },
  ];
  saveActivityTargeting;
  nextStepLabel;
  saveNotApplicable = false;
  saveCallback = async () => await this.saveActivityTargetingFn(this);
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  customValidation = () => this.validateNavigation('', '', this);
  resetFormCallback = () => this.resetForm();

  isCompletionTarget = false;
  _minimumNumberOfCompletes = 1;
  completionTarget: number;
  isSegmentTargetCompletionValid = false;
  hasSegmentTargetCompletionChanges = false;
  activityInfoForSegmentTargetCompletion: ActivityInfoForSegmentTargetCompletion;

  get targetingFilterType() {
    return this._targetingFilterType;
  }

  set targetingFilterType(value) {
    this._targetingFilterType = value;
    if (this.targetingFilterType === this.serverConstants.targetingFilterTypeConstants.bySegmentations) {
      this.initSegmentationData().then(() => {
        this.recalculateTargeting();
      });
    } else {
      this.recalculateTargeting();
    }
  }

  segmentations: any = [];

  enabledSegmentations: any = [];
  communicationGuid = '';

  activityGuid;
  showAdditionalTargeting;
  isActivityCommunicationCardTargeting = true;
  squareActivity;
  communication;
  data;
  previousIsCompleted: boolean = false;
  isReadOnly;
  // Activity is published
  isPublished;
  infoTextProvider: SequencedActivityInfoMessageHelper;
  isQuotaFeatureEnabled: boolean;
  isActivityTargetCompletionEnabled: boolean;
  isMaximumNumberCompletionSelected = false;
  isEndDatePassed = true;
  isUpdatingSamples = false;
  uniqueNrOfCompletes = 0;

  segmentTargets;

  async $onInit() {
    this.targeting = {
      Guid: '',
      TargetingType: this.serverConstants.targetingTypeConstants.activity,
      TargetingFilterOption: this.serverConstants.targetingFilterOptionConstants.all,
      Items: [],
      SquareParticipantIds: [],
      TargetingFilterType: this.serverConstants.targetingFilterTypeConstants.all,
      CommunicationGuid: '',
      Targeted: 0,
      TargetedFrom: 0,
      RandomlySelected: null,
      Matches: null,
      HasQuota: false,
      ExcludeParticipantsInvitedSinceWeeks: null,
    };

    if (this.isReadOnly === undefined) {
      this.isReadOnly = this.$stateParams.status === 'Closed';
    }

    this.removeLink = this.wizardStep.linkComponent('targeting', this);
    this.squareGuid = this.$stateParams.squareGuid;
    this.isQuotaFeatureEnabled = await this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.quota);
    this.isActivityTargetCompletionEnabled = await this.featureService.checkFeatureAccessibilityForSetup(
      this.serverConstants.featureConstants.activityTargetCompletion);
  }

  private setCommunicationGuid() {
    if (this.squareActivity !== undefined && this.squareActivity.ActivityQuantDetail !== undefined && this.squareActivity.ActivityQuantDetail !== null) {
      this.communicationGuid = this.squareActivity.ActivityQuantDetail.CommunicationGuid;
    }
    if (this.squareActivity !== undefined && this.squareActivity.ActivityQualDetail !== undefined && this.squareActivity.ActivityQualDetail !== null) {
      this.communicationGuid = this.squareActivity.ActivityQualDetail.CommunicationGuid;
    }
  }

  private initSegmentationData = _.memoize(() => {
    this.spinnerservice.show('loading');
    const includeArchived = this.squareActivity !== undefined && (
      this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.closed
      || this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.archived
    );
    return this.segmentationservice.getSquareSegmentations(true, true, includeArchived, this.activityGuid).then((segmentations) => {
      this.segmentations = segmentations;
      this.segmentations.segment = {};
      this.segmentationsLength = 0;

      if (this.segmentations.length > 0 && this.targeting.Items.length === 0) {
        this.targeting.Items.push({
          Id: this.segmentationsLength++,
          TargetingFilterOption: this.serverConstants.targetingFilterOptionConstants.all,
          SegmentationGuid: '',
          SegmentGuids: [],
          SelectedSegmentation: undefined,
          SelectedSegments: [],
        });
      }

      _.each(this.segmentations, (item: any) => {
        if (!item.SegmentItems) {
          item.SegmentItems = [];
        }
        this.segmentations.segment[item.Guid] = item.SegmentItems;
      });
    }).finally(() => {
      this.spinnerservice.hide('loading');
      this.loading = false;
    });
  });

  $onDestroy() {
    this.removeLink();
  }

  $wizardStepIsCompleted() {
    this.isCompleted = false;
    if (this.communication != null && this.communication.Targeting != null) {
      this.isCompleted = true;
    }

    if (this.squareActivity != null && this.squareActivity.Detail.HasTargeting) {
      this.isCompleted = true;
    }

    return this.isCompleted;
  }

  $wizardIsValid() {
    let targetingInvalid = false;
    let completionTargetInvalid = false;
    let segmentTargetCompletionInvalid = false;
    switch (this.targetingFilterType) {
      case this.serverConstants.targetingFilterTypeConstants.bySegmentations:
        // Invalid if there are no items or there's at least one item that's not filled in correctly
        targetingInvalid = this.targeting.Items.length <= 0 || _.sumBy(
          this.targeting.Items,
          (item) => item.SegmentationGuid === '' || item.SegmentGuids.length <= 0 ? 1 : 0) > 0;
        break;
      case this.serverConstants.targetingFilterTypeConstants.byMemberIds:
        // Invalid if there are no Id's filled in
        targetingInvalid = this.targeting.SquareParticipantIds.length <= 0;
        break;
    }

    completionTargetInvalid = (this.showActivityTargetCompletion() && this.isCompletionTarget && (this.completionTarget == null ||
      this.completionTarget < this._minimumNumberOfCompletes ||
      this.completionTarget > this.serverConstants.validationConstants.integerMaxValue));

    segmentTargetCompletionInvalid = (this.showActivityTargetCompletion() && !this.isSegmentTargetCompletionValid);

    const isValid = !targetingInvalid && !this.isSamplingInvalid()
      && (!this.targeting.HasQuota || this.isFeasible)
      && (this.previousIsCompleted || this.isCompleted)
      && !completionTargetInvalid && !segmentTargetCompletionInvalid;
    return isValid;
  }

  isSamplingInvalid(): boolean {
    if (this.isRandomizationAllowed && this.isRandomizationSelected &&
      (!this.targeting.RandomlySelected ||
        this.targeting.RandomlySelected < this.minimumRandomlySelected ||
        this.targeting.RandomlySelected > this.integerMaxValue)) {
      return true;
    }

    if (this.isExcludeParticipantsInvitedSinceWeeksSelected &&
      (!this.targeting.ExcludeParticipantsInvitedSinceWeeks ||
      this.targeting.ExcludeParticipantsInvitedSinceWeeks < 1 ||
      this.targeting.ExcludeParticipantsInvitedSinceWeeks > this.integerMaxValue)) {
      return true;
    }
    return false;
  }

  get isFeasible(): boolean {
    if (!this.targeting.HasQuota) {
      return true;
    }

    if (this.targeting.TargetingQuota == null || this.targeting.TargetingQuota.targetingQuotaItems == null) {
      return false;
    }

    return this.targeting.TargetingQuota.targetingQuotaItems.every((item) =>
      _.sum(item.targetingQuotaItemRows.map((row) => row.amountRandom)) === this.targeting.RandomlySelected);
  }

  loadInfo() {
    // Previous step at Quant: research
    this.wizardStep.resolveComponent(['builderQuant']).then((resolved) => {
      this.squareActivity = resolved[0].squareActivity;
      this.previousIsCompleted = resolved[0].$wizardStepIsCompleted();
    });
    // Previous step at Qual
    this.wizardStep.resolveComponent(['builderQual']).then((resolved) => {
      this.squareActivity = resolved[0].squareActivity;
      this.previousIsCompleted = resolved[0].$wizardStepIsCompleted();
    });
    // Previous step at divided Qual
    this.wizardStep.resolveComponent(['presentQual']).then((resolved) => {
      this.squareActivity = resolved[0].squareActivity;
      this.previousIsCompleted = resolved[0].$wizardStepIsCompleted();
    });
    // Previous step at Quant: profile
    if (this.wizard.steps[1].caption === 'Target') {
      this.wizardStep.resolveComponent(['specify']).then((resolved) => {
        this.squareActivity = resolved[0].squareActivity;
        this.previousIsCompleted = resolved[0].$wizardStepIsCompleted();
      });
    }

    this.wizardStep.resolveComponent(['channels']).then((resolved) => {
      this.previousIsCompleted = resolved[0].$wizardStepIsCompleted();
      this.targeting.TargetingType = this.serverConstants.targetingTypeConstants.communication;
    });
  }

  deleteSegmentationFromList(targeting) {
    this.$mdDialog.show(
      this.$mdDialog.iscConfirm()
        .title('Delete segmentation from list')
        .text(`Do you want to delete the segmentation ${this.getTargetingLabel(targeting)} from your targeting setup?`)
        .ok('Yes')
        .cancel('No'),
    ).then(() => {
      const index = _.findIndex<any>(this.targeting.Items, (x) => x.Id === targeting.Id);
      this.targeting.Items.splice(index, 1);
      this.recalculateTargeting();
    });
  }

  getTargetingLabel(targeting) {
    const segmentationGuid = targeting.SegmentationGuid;
    const segmentation = _.find<any>(this.segmentations, {
      Guid: segmentationGuid,
    });
    return segmentation
      ? segmentation.Question
      : '';
  }

  addSegmentationToList() {
    this.targeting.Items.push({
      Id: this.segmentationsLength++,
      TargetingFilterOption: this.serverConstants.targetingFilterOptionConstants.all,
      SegmentationGuid: '',
      SegmentGuids: [],
      SelectedSegments: [],
      SelectedSegmentation: undefined,
    });
  }

  gatherTargetingOptions(targeting, selectedSegmentation, selectedSegments) {
    const segmentationSwitch = targeting.SegmentationGuid && targeting.SegmentationGuid !== selectedSegmentation.Guid;
    if (segmentationSwitch && selectedSegments) {
      selectedSegments.splice(0, selectedSegments.length);
    }

    targeting.SegmentationGuid = selectedSegmentation.Guid;
    targeting.SegmentGuids = [];

    if (selectedSegments) {
      targeting.SegmentGuids = selectedSegments.map((item) => item.Guid);
    }

    this.recalculateTargeting();
  }

  getSegmentMembers(selectedSegmentation, selectedSegment, targetIndex) {
    if (selectedSegmentation && (!selectedSegment || !selectedSegment.length)) {
      return selectedSegmentation.MembersInSegmentation;
    }

    if (Object.prototype.hasOwnProperty.call(this.membersByTarget, targetIndex)) {
      return this.membersByTarget[targetIndex];
    }

    return 0;
  }

  resetForm() {
    this.form.$setPristine();
    this.$loadData();
  }

  showAddingOption() {
    if (this.segmentations.length > 0 && this.targeting.Items.length === 0) {
      return true;
    }

    if (this.targeting.Items.length === 0) {
      return false;
    }

    const lastTargetingOption = this.targeting.Items.slice(-1)[0];
    if (!lastTargetingOption.SegmentationGuid || lastTargetingOption.SegmentGuids.length === 0) {
      return false;
    }

    return true;
  }

  includeTargetingFilterOptionChangeFor(targetingRow, value) {
    targetingRow.TargetingFilterOption = value;
    this.recalculateTargeting();
  }

  includeTargetingFilterOptionChange(value) {
    this.targeting.TargetingFilterOption = value;
    this.recalculateTargeting();
  }

  onMemberIdsBlur() {
    this.setMemberIdsValidity(true);
    this.splitInputToMemberIds(this.memberIdsInputText);
    this.recalculateTargeting();
  }

  splitInputToMemberIds(input) {
    // Reset ids
    this.targeting.SquareParticipantIds = [];
    // Parse the input and populate SquareParticipantIds
    const regEx = /(\d+)/ig;
    let match = regEx.exec(input);
    while (match !== null) {
      const id = parseInt(match[1], 10);
      if (this.targeting.SquareParticipantIds.indexOf(id) === -1) {
        this.targeting.SquareParticipantIds.push(id);
      }
      match = regEx.exec(input);
    }
  }

  recalculateTargeting() {
    let promise: Promise<IMembersInSegmentsResponse>;
    this.targeting.TargetingFilterType = this.targetingFilterType;
    switch (this.targetingFilterType) {
      case this.serverConstants.targetingFilterTypeConstants.bySegmentations:
        promise = this.recalculateTargetingBySegmentations();
        break;
      case this.serverConstants.targetingFilterTypeConstants.byMemberIds:
        promise = this.recalculateTargetingByMemberIds();
        break;
      case this.serverConstants.targetingFilterTypeConstants.all:
        promise = this.recalculateTargetingAll();
        break;
    }
    this.isRecalculatingTargeting = true;
    promise.then(async (membersInSegments) => {
      if (this.showAdditionalTargeting) {
        await this.recalculateTargetingMatches(null, membersInSegments);
      }
      this.validateTargeting();
    });
    promise.finally(() => {
      this.isRecalculatingTargeting = false;
    });
  }

  async recalculateTargetingMatches(activityGuid: string = null, membersInSegments: IMembersInSegmentsResponse = null) {
    if (activityGuid && !this.activityGuid) {
      this.activityGuid = activityGuid;
      const activityTargeting = await this.targetService.getTargetingForActivity(this.activityGuid);
      if (activityTargeting) {
        this.randomlySelectedFromActivity = activityTargeting.RandomlySelected;
        this.isUpdatingSamples = this.squareActivity.Detail.TargetingStatus !== this.serverConstants.targetingStatusConstants.done;
      }
    }

    if (this.targetingFilterType === this.serverConstants.targetingFilterTypeConstants.byMemberIds) {
      if (this.targeting.SquareParticipantIds.length === 0) {
        this.targeting.Matches = 0;
        return;
      }
    } else {
      this.targeting.CommunicationGuid = this.communicationGuid;
    }

    membersInSegments = await this.targetService.getMembersInSegments(
      this.targeting, this.activityGuid, this.isExcludeParticipantsInvitedSinceWeeksSelected ? this.squareActivity?.Detail.StartDate : null, true);
    this.targeting.Matches = membersInSegments.MembersTargeted;
  }


  async recalculateTargetingAll(): Promise<IMembersInSegmentsResponse> {
    this.targeting.CommunicationGuid = this.communicationGuid;

    const response = await this.targetService.getMembersInSegments(
      this.targeting, this.activityGuid, this.isExcludeParticipantsInvitedSinceWeeksSelected ? this.squareActivity?.Detail.StartDate : null);
    this.targeting.Targeted = response.MembersTargeted;
    this.targeting.TargetedFrom = response.MembersTotal;
    this.minimumRandomlySelected = response.MembersStarted;
    this.membersByTarget = {};

    return response;
  }

  async recalculateTargetingBySegmentations(): Promise<IMembersInSegmentsResponse> {
    this.targeting.CommunicationGuid = this.communicationGuid;

    const response = await this.targetService.getMembersInSegments(
      this.targeting, this.activityGuid, this.isExcludeParticipantsInvitedSinceWeeksSelected ? this.squareActivity?.Detail.StartDate : null);
    this.targeting.Targeted = response.MembersTargeted;
    this.targeting.TargetedFrom = response.MembersTotal;
    this.minimumRandomlySelected = response.MembersStarted;
    this.membersByTarget = {};
    _.each(response.MembersByTargeting, (item: IMembersCountByTargeting) => {
      this.membersByTarget[item.Id] = item.MembersCount;
    });

    return response;
  }

  async recalculateTargetingByMemberIds(): Promise<IMembersInSegmentsResponse> {
    if (this.targeting.SquareParticipantIds.length === 0) {
      this.targeting.Targeted = 0;
      return;
    }

    const response = await this.targetService.getMembersInSegments(
      this.targeting, this.activityGuid, this.isExcludeParticipantsInvitedSinceWeeksSelected ? this.squareActivity?.Detail.StartDate : null);
    this.targeting.Targeted = response.MembersTargeted;
    this.targeting.TargetedFrom = response.MembersTotal;
    this.minimumRandomlySelected = response.MembersStarted;

    return response;
  }

  validateTargeting() {
    if (this.form) {
      if (this.form.targetingSegmentations) {
        this.form.targetingSegmentations.$setValidity('hasSegments', true);
      }
      if (this.form.memberIds) {
        this.form.memberIds.$setValidity('hasIds', true, null);
      }
      switch (this.targetingFilterType) {
        case this.serverConstants.targetingFilterTypeConstants.bySegmentations:
          {
            let hasNoSegments = true;

            _.each(this.targeting.Items, (item: ITargetingItemResponse) => {
              if (item.SegmentGuids.length > 0) {
                hasNoSegments = false;
              }
            });
            this.form.targetingSegmentations.$setValidity('hasSegments', !hasNoSegments);
          }
          break;
        case this.serverConstants.targetingFilterTypeConstants.byMemberIds:
          this.form.memberIds.$setValidity('hasIds', this.targeting.SquareParticipantIds.length > 0, null);
          break;
        case this.serverConstants.targetingFilterTypeConstants.all:
          break;
      }
    }
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    if (this.isReadOnly) {
      return '';
    }
    const isActivityTargeting = this.squareActivity !== undefined && this.isCompleted;
    const isCommunicationTargeting = this.communication !== undefined;
    if (isActivityTargeting && this.isPublished) {
      return '';
    }
    if (isCommunicationTargeting) {
      if (_.every(this.communication.Channels, (c: any) => c.Channel.IsPublished)) {
        return '';
      }
      if (_.every(this.communication.Channels, (c: any) => !c.Channel.IsPublished)) {
        return this.serverConstants.squareConstants.wizardNotPublishedStatus;
      }

      const draftChannels = _.filter(this.communication.Channels, (c: any) => !c.Channel.IsPublished);

      const mailChannels = {};
      mailChannels[this.serverConstants.channelTypeConstants.researchActivityReminder.toString()] = 'Activity reminder email';
      mailChannels[this.serverConstants.channelTypeConstants.researchActivityIncomplete.toString()] = 'Activity incomplete email';

      return `${_.map(draftChannels, (c) => mailChannels[c.Channel.ChannelType.toString()])
        .join(',')
        .replace(/,(?=[^,]*$)/, ' and ')
      } ${this.serverConstants.squareConstants.wizardNotPublishedStatus.toLowerCase()}`;
    }
    return this.serverConstants.squareConstants.wizardNotPublishedStatus;
  }

  updateSamplesAvailable(): Promise<void> {
    this.isUpdatingSamples = true;
    return this.$wizardBeforeNext(true);
  }

  async $wizardBeforeNext(forceTargeting: boolean = false) {
    if (this.squareActivity && this.squareActivity.isParentActivity) {
      await this.showParentActivityChangeConfirm();
    }
    if (this.saveActivityTargeting) {
      if (this.squareActivity?.ActivityQualDetail?.IsPublished === true) {
        const poboUsername = await this.poboService.checkPoboConfirmation(
          this.squareActivity.ActivityQualDetail.OpenPostUsername);
        this.squareActivity.ActivityQualDetail.OpenPostUsername = poboUsername;
      }
      await this.saveActivityTargetingFn(this, forceTargeting);
    }
  }

  $loadData = async () => {
    const isActivityTargeting = this.squareActivity != null;
    const data = await this.getTargetingForEntity(isActivityTargeting);
    this.isCompleted = data != null;

    if (this.activityGuid && !isActivityTargeting) {
      const activityTargeting = await this.getTargetingForEntity(true);
      if (activityTargeting) {
        this.randomlySelectedFromActivity = activityTargeting.RandomlySelected;
      }
    }
    return data;
  };

  private getTargetingForEntity = async (isActivityTargeting: boolean) => {
    if (!this.activityGuid && !this.communicationGuid) {
      // Oops, we are fetching data too soon
      return;
    }

    return await this.getTargetingForEntityMemoized(isActivityTargeting);
  };

  private getTargetingForEntityMemoized = _.memoize(async (isActivityTargeting: boolean) =>
    isActivityTargeting ?
      await this.targetService.getTargetingForActivity(this.activityGuid) :
      await this.targetService.getTargetingForCommunication(this.communicationGuid),
  );

  async $wizardStepLoaded() {
    await this.notifications.addUserToGroup(this.$stateParams.activityGuid);
    this.targetingNumbersUpdatedStatusSubscription = this.notifications
      .targetingNumbersUpdated
      .subscribe((event: ITargetingNumbersUpdated) => {
        if (this.$stateParams.activityGuid === event.ActivityGuid) {
          this.isUpdatingSamples = event.TargetingStatus !== this.serverConstants.targetingStatusConstants.done;
        }
      });

    this.spinnerservice.showFor('loading', this.initData().then(() => {
      this.wizardStep.resolveComponent(['channels']).then((result) => {
        const channelsStep = result[0];
        this.infoTextProvider = new SequencedActivityInfoMessageHelper(this.serverConstants, channelsStep);
      });

      // Keep member id input text in sync with the ids array
      this.$scope.$watch(
        () => this.targeting.SquareParticipantIds,
        (squareParticipantIds) => this.memberIdsInputText = squareParticipantIds.join('; '));

      this.$scope.$watch( // Clear completion target if option is not
        () => this.isCompletionTarget,
        () => {
          if (!this.isCompletionTarget) {
            this.completionTarget = null;
          }
        });

      this.$scope.$watch(
        () => this.targeting.ExcludeParticipantsInvitedSinceWeeks,
        () => this.recalculateTargeting());

      this.$scope.$watch(
        () => this.isExcludeParticipantsInvitedSinceWeeksSelected,
        () => {
          if (this.targeting.ExcludeParticipantsInvitedSinceWeeks) {
            this.recalculateTargeting();
          }
        });
      this.isRandomizationAllowed = this.squareActivity !== undefined;
    }));

    this.isEndDatePassed =
      this.wizard.steps.length > 0
        && this.wizard.steps[0].component?.model?.EndDateTime
        ? DateTime.utc() > this.wizard.steps[0].component.model.EndDateTime
        : false;
  }

  private async initData() {
    if (this.wizard.steps[0].component && this.wizard.steps[0].component.selectedActivityGuid) {
      this.activityGuid = this.wizard.steps[0].component.selectedActivityGuid;
      this.showAdditionalTargeting = true;
    }

    if (this.$stateParams.activityGuid) {
      this.activityGuid = this.$stateParams.activityGuid;
    }

    if (this.$stateParams.researchActivityGuid) {
      this.activityGuid = this.$stateParams.researchActivityGuid;
      this.showAdditionalTargeting = true;
    }

    if (!this.communicationGuid) {
      this.communicationGuid = this.$stateParams.communicationGuid;
    }

    this.setCommunicationGuid();

    const targeting = await this.$loadData();

    if (targeting) {
      this.targeting = angular.copy(targeting);
      this.targetingFilterType = targeting.TargetingFilterType;
      this.isRandomizationSelected = targeting.RandomlySelected != null;
      this.initialTargeting = angular.copy(this.targeting);
      this.isExcludeParticipantsInvitedSinceWeeksSelected = !!this.targeting.ExcludeParticipantsInvitedSinceWeeks;

      // Makes sure segmentations are loaded
      await this.initSegmentationData().then(() => {
        if (this.targetingFilterType !== this.serverConstants.targetingFilterTypeConstants.bySegmentations) {
          return;
        }

        _.each(this.targeting.Items, (item: ITargetingItemResponse) => {
          // Get segmentation object
          const segmentation = _.find<any>(this.segmentations, {
            Guid: item.SegmentationGuid,
          });
          item.SelectedSegmentation = segmentation;
          item.SelectedSegments = [];
          _.each(item.SegmentGuids, (segmentGuid: any) => {
            // Get segment object
            const segment = _.find<any>(item.SelectedSegmentation.SegmentItems, {
              Guid: segmentGuid,
            });
            item.SelectedSegments.push(segment);
          });
        });

      });
      this.recalculateTargeting();
    } else {
      this.targetingFilterType = this.serverConstants.targetingFilterTypeConstants.all;
      // If feature is toggled on, then completion target needs to be selected by default
      if (this.showActivityTargetCompletion()) {
        this.isCompletionTarget = true;
      }
    }

    this.loadInfo();

    if (this.showActivityTargetCompletion()) {
      this.isCompletionTarget = !!this.squareActivity.Detail.CompletionTarget;
      this.completionTarget = this.squareActivity.Detail.CompletionTarget;
    }

    // check to see if we are configuring an activity so we don't break communication targeting
    if (this.squareActivity) {
      this.activityInfoForSegmentTargetCompletion = {
        activityFormat: this.squareActivity.Detail.Format,
        activityStatus: this.squareActivity.Detail.Status,
        activityType: this.squareActivity.Detail.ActivityType,
      };
    }

    this.wizardStep.resolveComponent(['publishQual']).then((resolved) => {
      resolved[0].targeting = this.targetingFilterType;
    });
    this.wizardStep.resolveComponent(['segmentations']).then((resolved) => {
      resolved[0].previousIsCompleted = this.isCompleted;
    });

    this.saveNotApplicable = (this.communication && this.communication.Channels && _.some(this.communication.Channels, (c: any) => c.Channel.IsPublished))
      || (this.squareActivity && this.squareActivity.ActivityQualDetail && this.squareActivity.ActivityQualDetail.IsPublished)
      || this.isPublished;
  }

  validateNavigation(toParams, fromParams, root) {
    if (!root) {
      root = this;
    }
    return {
      canNavigate: root.form.$valid,
      isDirty: root.isDisabled ? false : root.hasChanges(),
    };
  }

  async saveActivityTargetingFn(root: this, forceTargeting: boolean = false): Promise<void> {
    if (this.isDisabled) {
      return;
    }
    // Get targeting data
    switch (root.targetingFilterType) {
      case root.serverConstants.targetingFilterTypeConstants.bySegmentations:
        root.targeting.SquareParticipantIds = [];
        root.targeting.Items = _.filter(root.targeting.Items,
          (item) => item.SegmentationGuid !== '' || item.SegmentGuids.length > 0);
        break;
      case root.serverConstants.targetingFilterTypeConstants.byMemberIds:
        root.targeting.Items = [];
        break;
      case root.serverConstants.targetingFilterTypeConstants.all:
        root.targeting.Items = [];
        root.targeting.SquareParticipantIds = [];
        break;
    }

    if (this.squareActivity?.Detail) {
      this.squareActivity.Detail.IsCompletionTarget = this.isCompletionTarget;
      this.squareActivity.Detail.CompletionTarget = this.isCompletionTarget ? this.completionTarget : null;
    }

    let segmentTargetData;
    if (this.isActivityTargetCompletionEnabled) {
      segmentTargetData =
        _.flatMap(this.segmentTargets, (segmentation) => {
          if (segmentation.Question !== '' && segmentation.SegmentItems.length) {
            return _.map(segmentation.SegmentItems, (segment) =>
              ({ SegmentGuid: segment.Guid, Target: segment.Target }));
          }
        });
      segmentTargetData = _.filter(segmentTargetData, (s) => !!s);// Filter out empty values
    }

    root.targeting.TargetingFilterType = root.targetingFilterType;
    const data = {
      Targeting: root.targeting,
      CommunicationGuid: root.communicationGuid,
      ActivityGuid: root.activityGuid,
      CompletionTarget: this.squareActivity?.Detail?.IsCompletionTarget ? +this.squareActivity.Detail.CompletionTarget : null,
      ActivitySegmentTargets: segmentTargetData,
      ForceTargeting: forceTargeting,
    };
    if (root.initialTargeting && root.initialTargeting.TargetedFrom) {
      // Preserve the original targetedfrom as its the real targeting
      // And that is the number of active participants in square
      data.Targeting.TargetedFrom = root.initialTargeting.TargetedFrom;
    }
    if (!root.isRandomizationSelected || !root.isRandomizationAllowed) {
      data.Targeting.RandomlySelected = null;
    }
    if (data.Targeting.RandomlySelected === null) {
      data.Targeting.HasQuota = false;
    }
    if (!data.Targeting.HasQuota) {
      data.Targeting.TargetingQuota = null;
    }
    if (!root.isExcludeParticipantsInvitedSinceWeeksSelected) {
      data.Targeting.ExcludeParticipantsInvitedSinceWeeks = null;
    }

    const isActivityTargeting = root.squareActivity !== undefined;
    const isCommunicationTargeting = root.communication !== undefined;

    root.getTargetingForEntityMemoized.cache = new _.memoize.Cache();

    const promise = isActivityTargeting ?
      root.targetService.createUpdateActivityTargeting(data) :
      root.targetService.createUpdateCommunicationTargeting(data);

    return promise
      .then(() => {
        if (isActivityTargeting) {
          if (this.isActivityTargetCompletionEnabled) {
            this.$scope.$broadcast('onActivityTargetingSave');
          }
          root.squareActivity.Detail.HasTargeting = true;
          if (!root.isReadOnly) {
            root.logger.success('Activity targeting successfully updated');
          }
        }
        if (isCommunicationTargeting) {
          root.communication.Targeting = root.targeting;
          if (!root.isReadOnly) {
            root.logger.success('Communication targeting successfully updated');
          }
        }
        root.form.$setPristine();
        if (root.form.memberIds) {
          root.form.memberIds.$setValidity('serverErrors', true);
        }
        root.initialTargeting = null;

      }, (error) => {
        if (error.status === 400 && error.data) {
          if (error.data.Message) {
            root.logger.error(error.data.Message);
          }
          const grouped = _.groupBy(error.data.ValidationErrors, 'PropertyName');
          _.forEach(grouped, (item, key) => {
            let message = '';
            _.forEach(item, (errorMessage) => {
              message += `${errorMessage.ErrorMessage} `;
            });
            if (root.form[key]) {
              root.form[key].$setValidity('serverErrors', false);
              root.form[key].errorMessage = message;
            } else {
              this.form.$setValidity(key, false, null);
            }

          });
        } else {
          if (isActivityTargeting) {
            root.logger.error('Activity targeting not successfully updated');
          }
          if (isCommunicationTargeting) {
            root.logger.error('Communication targeting not successfully updated');
          }
        }
        return this.$q.reject(error);
      });
  }

  get isDisabled() {
    return (this.squareActivity && this.squareActivity.isChildActivity) || this.isReadOnly;
  }

  protected hasChanges(): boolean {
    if (this.showActivityTargetCompletion() && this.hasSegmentTargetCompletionChanges) {
      return true;
    }
    if (!this.initialTargeting) {
      return false;
    }
    let hasChanges = this.targetingFilterType !== this.initialTargeting.TargetingFilterType
      || this.initialTargeting.RandomlySelected !== this.targeting.RandomlySelected
      || this.initialTargeting.ExcludeParticipantsInvitedSinceWeeks !== this.targeting.ExcludeParticipantsInvitedSinceWeeks;
    if (hasChanges) {
      return hasChanges;
    }

    switch (this.targetingFilterType) {
      case this.serverConstants.targetingFilterTypeConstants.bySegmentations:
      {
        const initialSegmentGuids = [];
        const currentSegmentGuids = [];
        this.initialTargeting.Items.forEach((element) => {
          element.SegmentGuids.forEach((guid) => {
            initialSegmentGuids.push(guid);
          });
        });
        this.targeting.Items.forEach((element) => {
          element.SegmentGuids.forEach((guid) => {
            currentSegmentGuids.push(guid);
          });
        });
        hasChanges = !_.isEqual(initialSegmentGuids.sort(), currentSegmentGuids.sort());
        break;
      }
      case this.serverConstants.targetingFilterTypeConstants.byMemberIds:
        hasChanges = !this.initialTargeting.SquareParticipantIds;
        if (this.initialTargeting.SquareParticipantIds && angular.isArray(this.initialTargeting.SquareParticipantIds)) {
          const initial = angular.copy(this.initialTargeting.SquareParticipantIds);
          const current = angular.copy(this.targeting.SquareParticipantIds);
          hasChanges = !_.isEqual(initial.sort(), current.sort());
        }
        break;
      case this.serverConstants.targetingFilterTypeConstants.all:
        hasChanges = (this.initialTargeting.Items && this.initialTargeting.Items.length > 0) ||
          (this.initialTargeting.SquareParticipantIds && this.initialTargeting.SquareParticipantIds.length > 0);
        break;
    }

    return hasChanges;
  }

  private setMemberIdsValidity(isValid) {
    const memberIdsModelController = this.form.memberIds as ng.INgModelController;
    memberIdsModelController.$setTouched();
    memberIdsModelController.$setValidity('validIds', isValid);
  }

  isLowerThanCompletionTargetLimit() {
    const types = this.serverConstants.squareActivityTypeConstants;

    return (this.completionTarget != null
      && (
        (this.squareActivity.Detail.ActivityType === types.publicQualitativeResearch
          && this.completionTarget < this.serverConstants.completionTargetConstants.forumDiscussionMin)
        ||
        (this.squareActivity.Detail.ActivityType === types.privateQualitativeResearch
          && this.completionTarget < this.serverConstants.completionTargetConstants.privateDiscussionMin)
        ||
        (this.squareActivity.Detail.ActivityType === types.privateScoutResearch
          && this.completionTarget < this.serverConstants.completionTargetConstants.lifeScenesMin)
        ||
        (this.squareActivity.Detail.ActivityType === types.publicScoutResearch
          && this.completionTarget < this.serverConstants.completionTargetConstants.lifeSnapshotsMin)
        ||
        ( // Is research survey
          (this.squareActivity.Detail.ActivityType === types.checkMarketSurvey
            || this.squareActivity.Detail.ActivityType === types.confirmitSurvey
            || this.squareActivity.Detail.ActivityType === types.decipherSurvey
            || this.squareActivity.Detail.ActivityType === types.decipherDiarySurvey
            || this.squareActivity.Detail.ActivityType === types.instinctSurvey)
          && this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.research
          && this.completionTarget < this.serverConstants.completionTargetConstants.researchActivityMin)
      ));
  }

  isHigherThanCompletionTargetLimit() {
    const types = this.serverConstants.squareActivityTypeConstants;

    return (this.completionTarget != null
      && (
        (this.squareActivity.Detail.ActivityType === types.publicQualitativeResearch
          && this.completionTarget > this.serverConstants.completionTargetConstants.forumDiscussionMax)
        ||
        (this.squareActivity.Detail.ActivityType === types.privateQualitativeResearch
          && this.completionTarget > this.serverConstants.completionTargetConstants.privateDiscussionMax)
        ||
        (this.squareActivity.Detail.ActivityType === types.privateScoutResearch
          && this.completionTarget > this.serverConstants.completionTargetConstants.lifeScenesMax)
        ||
        (this.squareActivity.Detail.ActivityType === types.publicScoutResearch
          && this.completionTarget > this.serverConstants.completionTargetConstants.lifeSnapshotsMax)
        ||
        ( // Is research survey
          (this.squareActivity.Detail.ActivityType === types.checkMarketSurvey
            || this.squareActivity.Detail.ActivityType === types.confirmitSurvey
            || this.squareActivity.Detail.ActivityType === types.decipherSurvey
            || this.squareActivity.Detail.ActivityType === types.decipherDiarySurvey
            || this.squareActivity.Detail.ActivityType === types.instinctSurvey)
          && this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.research
          && this.completionTarget > this.serverConstants.completionTargetConstants.researchActivityMax)
      ));
  }

  showActivityTargetCompletion(): boolean {
    return this.isActivityTargetCompletionEnabled && !!this.squareActivity;
  }
}
