'use strict';

import { IPrepareSurveyFinished } from '../../../../../core/contracts/activity.contract';
import { NotificationsService } from '../../../../../core/services/notifications';
import { ServerConstants } from '../../../../../core/serverconstants';
import { ActivityService } from '../../../../../core/dataservices/activity.service';
import { CommunicationService } from '../../../../../core/dataservices/communication.service';
import { FeatureService } from '../../../../../core/dataservices/feature.service';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import { WizardTargetingController } from '../../../../../layout/wizard-targeting/wizard-targeting.controller';
import { ActivitySegmentationController } from '../activitySegmentation/activitySegmentation.controller';
import { DateFormatService } from '../../../../../core/services/dateformat.service';
import * as _ from 'lodash';
import { IDecipherConfiguration } from '../../../../../core/contracts/activity.contract';
import { CurrentUserService } from '../../../../../core/dataservices/currentUser.service';

export class ActivityPublishController {
  static $inject = ['activityservice', '$stateParams', '$state', 'logger', 'serverConstants', '$mdDialog',
    'communicationservice', 'spinnerservice', '$q', 'featureservice', 'dateFormatService', 'notifications', 'currentUserService'];

  private surveyPreparationStatusSubscription = _.noop;

  removeLink;
  squareActivityFormatConstants = this.serverConstants.squareActivityFormatConstants;
  validationConstants = this.serverConstants.validationConstants;
  communicationChannelConstants = this.serverConstants.communicationChannelConstants;
  channelTypeConstants = this.serverConstants.channelTypeConstants;

  Format;
  squareActivity;
  wizardStep;
  $wizardStepExtraButtons = [];
  urlExec = false;

  showCredits = false;
  showRewards = false;
  showSavingUp = false;
  showDate = false;
  showSticky = false;
  showShowOnHomepage = false;
  showShowOnPublicPage = false;
  showRecruitmentPartner = false;
  showTarget = false;

  target;
  recruitmentPartners = [];
  startDate;
  endDate;
  offSet = '';
  segmentations = '';
  sticky = false;
  showOnHomepage = false;
  credits = '';
  rewards = [];
  execDateTime;
  completionTarget = '';
  isCompletionTarget = false;
  completionPoints: number;
  activityTypes;
  componentOptions = [];
  squareGuid = '';
  isPreviousStepCompleted = false;
  isPublished;
  surveyPreparationStatus: number;
  isReadOnly;
  Type;
  isSavingUpReward = false;
  isValidatingCanPublish = false;

  constructor(
    private activityservice: ActivityService,
    private $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    private logger,
    private serverConstants: ServerConstants,
    private $mdDialog: ng.material.IDialogService,
    private communicationservice: CommunicationService,
    private spinnerservice: SpinnerService,
    private $q: ng.IQService,
    private featureService: FeatureService,
    private dateFormatService: DateFormatService,
    private notifications: NotificationsService,
    private currentUserService: CurrentUserService,
  ) {
  }

  async $onInit() {
    // Saving Up here is enabled only if
    //        Is enabled in master and at square level
    this.isSavingUpReward = await this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards);
    this.activityTypes = this.serverConstants.squareActivityTypeConstants;
    this.squareGuid = this.$stateParams.squareGuid;
    this.Format = this.squareActivity.Detail.Format;
    this.Type = this.squareActivity.Detail.ActivityType;
    await this.initPublishComponentToShow(this.squareActivity.Detail.Format);
    this.removeLink = this.wizardStep.linkComponent('finish', this);
    this.getGoToButton();
  }

  async $wizardStepLoaded() {
    await this.notifications.addUserToGroup(this.$stateParams.activityGuid);
    this.surveyPreparationStatusSubscription = this.notifications
      .prepareSurveyFinished
      .subscribe((event: IPrepareSurveyFinished) => {
        if (this.$stateParams.activityGuid === event.ActivityGuid) {
          this.surveyPreparationStatus = event.IsFinished
            ? this.serverConstants.surveyPreparationStatusConstants.finished
            : this.serverConstants.surveyPreparationStatusConstants.notStarted;
        }
      });
    this.urlExec = true;
    this.wizardStep.resolveComponent(this.componentOptions)
      .then((resolved) => {
        const format = resolved[0].model.Format;
        this.startDate = this.dateFormatService.getDateTime(resolved[0].model.StartDateTime, resolved[0].model.DateOffset);
        this.endDate = this.dateFormatService.getDateTime(resolved[0].model.EndDateTime, resolved[0].model.DateOffset);
        this.offSet = resolved[0].model.DateOffset;
        this.credits = resolved[0].model.Credits;
        this.rewards = resolved[0].model.Rewards;
        this.execDateTime = resolved[0].model.ExecDateTime;
        if (format !== this.squareActivityFormatConstants.screener) {
          this.completionTarget = resolved[0].isActivityTargetCompletionEnabled ? resolved[2].completionTarget : resolved[0].model.CompletionTarget;
          this.isCompletionTarget = resolved[0].isActivityTargetCompletionEnabled ? resolved[2].isCompletionTarget : resolved[0].model.IsCompletionTarget;
          this.completionPoints = resolved[0].model.CompletionPoints;
          this.target = resolved.find((r) => r instanceof WizardTargetingController);
          if ((this.Type === this.activityTypes.confirmitSurvey
            || this.Type === this.activityTypes.decipherSurvey
            || this.Type === this.activityTypes.decipherDiarySurvey
            || this.Type === this.activityTypes.instinctSurvey)) {
            const segments = resolved.find((r) => r instanceof ActivitySegmentationController);
            if (segments
              && segments.segmentations
              && Array.isArray(segments.segmentations)
              && segments.segmentations.length > 0) {
              this.segmentations = segments.segmentations.map((elem) => elem.Name).join(', ');
            } else {
              this.segmentations = null;
            }
          }
        }

        if (format === this.squareActivityFormatConstants.screener) {
          this.recruitmentPartners = resolved[1].activityRecruitmentPartners;
        } else if (format === this.squareActivityFormatConstants.research) {
          this.sticky = resolved[1].model.Sticky;
          this.showOnHomepage = resolved[1].model.ShowOnHomepage;
        }
      });
  }

  $wizardStepIsCompleted() {
    return this.isPublished;
  }

  async initPublishComponentToShow(activityFormat) {
    // Note: URL is shown by default
    switch (activityFormat) {
      case this.squareActivityFormatConstants.research:
        this.showCredits = this.showRewards =
          this.Type === this.activityTypes.confirmitSurvey
          || this.Type === this.activityTypes.decipherSurvey
          || this.Type === this.activityTypes.instinctSurvey;
        this.showSavingUp = true;
        this.showDate = true;
        this.showSticky = true;
        this.showShowOnHomepage = true;
        this.showShowOnPublicPage = await this.featureService.checkFeatureAccessibilityForSetup(
          this.serverConstants.featureConstants.curationFeedbackCircle);
        this.showTarget = true;
        this.componentOptions = ['specify', 'builderQuant', 'targeting'];
        if (this.Type === this.activityTypes.confirmitSurvey
          || this.Type === this.activityTypes.decipherSurvey
          || this.Type === this.activityTypes.decipherDiarySurvey
          || this.Type === this.activityTypes.instinctSurvey) {
          this.componentOptions.push('segmentations');
        }
        break;
      case this.squareActivityFormatConstants.profile:
        this.showCredits = true;
        this.showSavingUp = true;
        this.showDate = true;
        this.showTarget = true;
        this.componentOptions = ['specify', 'targeting', 'segmentations'];
        break;
      case this.squareActivityFormatConstants.screener:
        this.showDate = true;
        this.showRecruitmentPartner = true;
        this.componentOptions = ['specify', 'recruitment sources'];
        break;
      default: break;
    }
  }

  $wizardNextLabel() {
    return 'Publish';
  }

  $wizardNextDescription() {
    if (!this.isReadOnly && this.isPublished) {
      return '';
    }
    if (!this.$wizardIsValid()) {
      if (this.notifications.isConnected) {
        return this.serverConstants.squareConstants.surveyNotReadyStatus;
      }
      return this.serverConstants.squareConstants.surveyNotReadyStatusNoSignalR;
    }
    return this.serverConstants.squareConstants.wizardNotPublishedStatus;
  }


  async $wizardBeforeNext() {
    if (!await this.validateCanPublish()) {
      return;
    }

    const promises = this.Type === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey ?
      [this.activityservice.publishActivity(this.$stateParams.activityGuid), this.activityservice.prepareSurvey(this.$stateParams.activityGuid)] :
      [this.activityservice.publishActivity(this.$stateParams.activityGuid)];

    return this.$q.all(promises)
      .then(() => {
        if (!this.isReadOnly) {
          this.logger.success('Activity successfully published');
          this.isPublished = true;
        }
      }).finally(() => {
        this.$state.go('root.square.activities.all', {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
        });
      });
  }

  private getIncorrectConfigurationErrorMessage(configuration: IDecipherConfiguration): string {
    if (!configuration.xmlFound) {
      return 'XML was not found for the survey';
    }
    if (!configuration.surveyNodeFound) {
      return 'No \'survey\' node was found in the XML';
    }
    let errorMessage = '';
    if (configuration.alwaysSaveDataConfigured === configuration.delphiConfigured) {
      errorMessage = `${errorMessage}- 'alwaysSaveData' and 'delphi' are both set to either '1' or '0'. One of both has to be '1' and the other '0'.\n`;
    }
    if (!configuration.customJSConfigured) {
      errorMessage = `${errorMessage}- 'customJS' does not reference 'iframeresizer'.\n`;
    }
    if (!configuration.loadDataIsConfigured) {
      errorMessage = `${errorMessage}- 'loadData' is not set to 'UniqueIdentifier'.\n`;
    }
    if (!configuration.uniqueIdentifierInExtraVariables) {
      errorMessage = `${errorMessage}- 'UniqueIdentifier' is not included in 'extraVariables'.\n`;
    }

    return errorMessage;
  }

  private async validateCanPublish(): Promise<boolean> {
    if (this.currentUserService.role === this.serverConstants.roleConstants.clientEditor) {
      return false;
    }

    this.isValidatingCanPublish = true;

    if (this.Type === this.serverConstants.squareActivityTypeConstants.decipherSurvey
    || this.Type === this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey) {
      // Check if the survey is live
      const isSurveyPublished = await this.activityservice.getIsDecipherSurveyPublished(this.$stateParams.activityGuid);
      // If not we show a message and return
      if (!isSurveyPublished) {
        this.$mdDialog.show(this.$mdDialog.iscAlert({
          title: 'Publish failed',
          text: 'It seems that your survey has not been published yet in Decipher',
          ok: 'OK',
        }));
        this.isValidatingCanPublish = false;
        return false;
      }

      if (this.Format === this.serverConstants.squareActivityFormatConstants.profile) {
        // Check if configured correctly
        const configuration = await this.activityservice.getIsDecipherProfileConfiguredCorrectly(this.$stateParams.activityGuid);
        const errorMessage = this.getIncorrectConfigurationErrorMessage(configuration);

        // If not configured correctly show dialog and return. Otherwise continue.
        if (errorMessage !== '') {
          // Show dialog
          this.$mdDialog.show(this.$mdDialog.iscAlert({
            title: 'Publish failed',
            text: `It seems that your survey is not configured correctly to be used as a profile survey.\n${errorMessage}`,
            ok: 'OK',
          }));
          this.isValidatingCanPublish = false;
          return false;
        }
      }
    }

    this.isValidatingCanPublish = false;
    return true;
  }

  async getGoToButton() {
    if (this.Format === this.squareActivityFormatConstants.research) {
      const existingComm = await this.communicationservice.communicationExistsForActivity(this.$stateParams.activityGuid);
      if (existingComm && existingComm.data === false) {
        const isDisabled = this.isValidatingCanPublish || this.currentUserService.role === this.serverConstants.roleConstants.clientEditor;
        this.$wizardStepExtraButtons = [
          {
            label: 'Publish & Go to Communication Wizard',
            isDisabled,
            onclick: async () => {
              if (!await this.validateCanPublish()) {
                return;
              }
              this.$mdDialog.show(this.$mdDialog.iscPrompt()
                .title('Create communication')
                .text('Enter a title for your communication *')
                .placeholder('Communication title')
                .ok('Create and go to communication wizard')
                .cancel('Cancel')
                .requiredText(this.validationConstants.communicationTitleEmptyValidationMessage)
                .maxLength(this.validationConstants.communicationTitleMaxLength)
                .maxLengthMessage(this.validationConstants.communicationTitleLengthValidationMessage)
                .clickOutsideToClose(),
              ).then((title) => {
                const promises = [this.activityservice.publishActivity(this.$stateParams.activityGuid),
                  this.communicationservice.createCommunication(title)];
                if (this.Type === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey) {
                  promises.push(this.activityservice.prepareSurvey(this.$stateParams.activityGuid));
                }

                const createPromise = this.$q.all(promises)
                  .then((response) => {
                    this.communicationservice.createUpdateActivityEmailChannels(response[1].data.Guid,
                      this.$stateParams.activityGuid, [{
                        Title: title,
                        ChannelType: this.channelTypeConstants.researchActivityEmail,
                        CommunicationGuid: response[1].data.Guid,
                      }]).then(
                      () => {
                        this.$state.go('root.square.communicationparent.wizard', {
                          clientGuid: this.$stateParams.clientGuid,
                          programGuid: this.$stateParams.programGuid,
                          squareGuid: this.$stateParams.squareGuid,
                          researchActivityGuid: this.$stateParams.activityGuid,
                          communicationGuid: response[1].data.Guid,
                        },
                        );

                      });
                  }, (error) => {
                    if (error.status === 400) {
                      const data = error.data;
                      let message = '';
                      _.forEach<any[]>(data.ValidationErrors, (validationError: any) => {
                        message += `${validationError.ErrorMessage} `;
                      });
                      if (message.length > 0) {
                        this.logger.error(message);
                      }
                    }
                  });
                this.spinnerservice.showFor('loading', createPromise);
              });
            },
            class: 'redirect-to-communication-wizard',
          },
        ];
      }
    }
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardIsValid() {
    return this.surveyPreparationStatus === this.serverConstants.surveyPreparationStatusConstants.finished;
  }
}
