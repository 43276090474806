'use strict';

import { Utils } from '../../../../core/utils';
import * as _ from 'lodash';
import { CurrentUserService } from '../../../../core/dataservices/currentUser.service';
import { SquareService } from 'src/app/core/dataservices/square.service';

export class ChangeRoleController {
  static $inject = [
    '$mdDialog',
    'serverConstants',
    'currentUserService',
    '$stateParams',
    'squareservice',
  ];

  roles = [];
  selectedRole;
  isDiySquare = false;

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private serverConstants,
    private currentUserService: CurrentUserService,
    private $stateParams: ng.ui.IStateParamsService,
    private squareservice: SquareService,
  ) {
  }

  async $onInit() {
    await this.squareservice.getSquareDetails(this.$stateParams.squareGuid)
      .then((data) => this.isDiySquare = data.data.Detail.JobIds.some((job) => job.IsSelfServe));

    const roleConstants = this.serverConstants.roleConstants;
    const currentRole = this.currentUserService.userProfile.Role;

    this.roles = _.filter(Utils.getEnumAsArray(roleConstants), (role) => {
      // Always pickable
      const isParticipant = role.Value === roleConstants.participant;
      const isObserver = role.Value === roleConstants.observer;

      // These roles can only be changed by human8 member
      const isProfessionalOrClientAdmin =
        (role.Value === roleConstants.professionalAdmin ||
        (role.Value === roleConstants.clientAdmin && this.isDiySquare) ||
        (role.Value === roleConstants.clientEditor && this.isDiySquare)) && currentRole === roleConstants.human8;

      return isParticipant || isObserver || isProfessionalOrClientAdmin;
    });

    const sortingOrder = [
      roleConstants.human8,
      roleConstants.professionalAdmin,
      roleConstants.clientAdmin,
      roleConstants.clientEditor,
      roleConstants.observer,
      roleConstants.participant,
    ];

    this.roles.sort((a, b) => sortingOrder.indexOf(a.Value) - sortingOrder.indexOf(b.Value));
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  save() {
    this.$mdDialog.hide(this.selectedRole);
  }

  get isValid() {
    return _.isNumber(this.selectedRole);
  }
}
