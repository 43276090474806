'use strict';

import { ActivityFilterService } from '../../core/services/activityFilter.service';
import { ActivityService } from '../../core/dataservices/activity.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { JobService } from './../../core/jobs/job.service';
import { ServerConstants } from '../../core/serverconstants';
import { HelperService } from '../../core/services/helper.service';
import * as _ from 'lodash';
import { SearchService } from '../../core/dataservices/search.service';

const addActivityDialogTemplate = require('./addActivityDialog.html');

require('./squareActivities.scss');

export class SquareActivitiesController {
  static $inject = ['activityservice', 'serverConstants', '$q', '$state', '$stateParams', '$mdDialog', 'logger', 'spinnerservice', 'helperservice',
    'activityFilterService', 'jobservice', 'searchservice'];
  activityAlreadyExistsMessage = 'Activity with this name already exists.';
  newActivityName = '';
  filterData;

  createSquareActivityValidation = [{
    name: 'uniqueForClient',
    isAsync: true,
    errorMessage: this.activityAlreadyExistsMessage,
    validate: (name) => {
      if (!name) {
        return this.$q.resolve();
      }
      return this.activityservice.checkActivityNameIsDuplicate(name).then((response) => {
        if (response.data.IsDuplicate) {
          return this.$q.reject();
        }
        return this.$q.resolve();

      });
    },
  }];

  pagination = {
    limit: 10,
    page: 1,
  };
  selectedTab = 0;
  constructor(
    private activityservice: ActivityService,
    private serverConstants: ServerConstants,
    private $q: ng.IQService,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $mdDialog: ng.material.IDialogService,
    private logger: Logger,
    private spinnerservice: SpinnerService,
    private helperservice: HelperService,
    private activityFilterService: ActivityFilterService,
    private jobService: JobService,
    private searchService: SearchService,
  ) {}

  $onInit() {
    this.selectCurrentTabOrDefault();
  }

  selectCurrentTabOrDefault() {
    switch (this.$state.current.name) {
      case 'root.square.activities.quant':
        this.selectedTab = 1;
        break;
      case 'root.square.activities.qual':
        this.selectedTab = 2;
        break;
      default:
        this.selectedTab = 0;
    }
  }

  getStateParamsFromFilter(filterData) {
    if (!filterData) {
      return {};
    }
    return this.activityFilterService.getStateParamsFromFilter(filterData);
  }

  filterActivitiesByQuant() {
    this.navigateTo('root.square.activities.quant');
  }

  filterActivitiesByQual() {
    this.navigateTo('root.square.activities.qual');
  }

  filterActivitiesByAll() {
    this.navigateTo('root.square.activities.all');
  }

  navigateTo(tab: string) {
    const filterParams = this.getStateParamsFromFilter(this.filterData);
    this.$state.go(tab, {
      clientGuid: this.$stateParams.clientGuid,
      programGuid: this.$stateParams.programGuid,
      squareGuid: this.$stateParams.squareGuid,
      ...filterParams,
    }, {
      reload: tab,
    });
  }

  createActivity() {
    this.$mdDialog.show({
      controller: 'AddActivityDialogController',
      controllerAs: 'vm',
      templateUrl: addActivityDialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
    }).then((clonedActivityGuid) => {
      const createPromise = this.activityservice.createActivity(this.newActivityName, this.$stateParams.programGuid,
        this.$stateParams.squareGuid, clonedActivityGuid)
        .then(async (response) => {
          if (response.data.CloneJobId) {
            this.spinnerservice.hideAll();
            await this.jobService.showJobProgressDialog(response.data.CloneJobId, 'Cloning activity', false);
          }
          this.goToActivity(response.data);
        }, (error) => {
          if (error.status === 400) {
            const data = error.data;
            let message = '';
            _.forEach < any[] > (data.ValidationErrors, (validationError: any) => {
              message += `${validationError.ErrorMessage} `;
            });

            if (message.length > 0) {
              this.logger.error(message);
            }
          }
        });
      this.spinnerservice.showFor('loading', createPromise);
    });
  }

  goToActivity(activity) {
    this.searchService.invalidate();
    this.helperservice.goToEntity('activity', activity);
  }
}
