'use strict';

import { ServerConstants } from '../../core/serverconstants';
import { ActivityFilterService } from '../../core/services/activityFilter.service';
import { AuthService } from '../../core/dataservices/auth.service';
import { ActivityService } from '../../core/dataservices/activity.service';
import { RouterHelper } from '../../blocks/router/router-helper';
import { DateTime } from 'luxon';

const routeConfig = (
  routerHelper: RouterHelper,
  activityservice: ActivityService,
  serverConstants: ServerConstants,
  authService: AuthService,
  activityFilterService: ActivityFilterService) => {
  routerHelper.configureStates([
    {
      state: 'root.square.activity',
      config: {
        url: `/activity/:activityGuid?referer?${activityFilterService.getQualFilterUrlParts()}`,
        resolve: {
          activityDestinationRoute: ['$stateParams', '$state', async ($stateParams, $state) => {
            const response = await activityservice.getActivityDetails($stateParams.activityGuid, $stateParams.clientGuid);
            let redirectRouteName = 'root.square.activityinfo';
            // Switches to the coresponding page based on the redirect diagram
            switch (response.data.Status) {
              // Closed and active status have the same redirects
              case serverConstants.squareActivityStatusConstants.closed:
              case serverConstants.squareActivityStatusConstants.active:
                if (activityservice.isQuantActivity(response.data.Type)) {
                  redirectRouteName = 'root.square.activityhealth';
                }
                if (activityservice.isQualResearchActivity(response.data.Type)) {
                  // Use the YYYY-MM-DD format
                  $stateParams = {
                    ...$stateParams,
                    startDate: DateTime.fromISO($stateParams.startDate ?? response.data.StartDate).toFormat('yyyy-LL-dd'),
                    endDate: DateTime.fromISO($stateParams.endDate ?? response.data.EndDate).toFormat('yyyy-LL-dd'),
                  };

                  if ($stateParams.segmentsOption === 1) {
                    delete $stateParams.segmentsOption;
                  }

                  redirectRouteName = 'root.square.activitydata';
                  activityservice.setModerationAndAnalysisDiscussionType(redirectRouteName);
                }
                break;
              case serverConstants.squareActivityStatusConstants.scheduled:
                if (authService.isObserverUserLogin()) {
                  switch (response.data.Type) {
                    case serverConstants.squareActivityTypeConstants.confirmitSurvey:
                    case serverConstants.squareActivityTypeConstants.decipherSurvey:
                    case serverConstants.squareActivityTypeConstants.decipherDiarySurvey:
                    case serverConstants.squareActivityTypeConstants.instinctSurvey:
                      redirectRouteName = 'root.square.activityhealth';
                      break;
                    case serverConstants.squareActivityTypeConstants.qualitativeResearch:
                      redirectRouteName = 'root.square.activitydata';
                      break;
                  }
                } else {
                  redirectRouteName = 'root.square.activityWizardType';
                }
                break;
              case serverConstants.squareActivityStatusConstants.draft:
                if (response.data.IsCompleted) {
                  redirectRouteName = 'root.square.activityWizardType';
                }
                break;
            }
            return $state.go(redirectRouteName, $stateParams, { location: 'replace' });
          }],
        },
      },
    },
    {
      state: 'root.square.activityinfo',
      config: {
        url: '/activity/:activityGuid/info?referer',
        component: 'activityInfoComponent',
        title: 'Activity info',
        settings: {
          nav: 1,
          section: 'ACTIVITY SET-UP',
          icon: 'fa fa-gauge',
          label: 'Activity info',
        },
        disallowRoles: ['Observer'],
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper', 'activityservice', 'serverConstants', 'authService', 'activityFilterService'];

export default routeConfig;
