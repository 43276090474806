'use strict';

import { IHttpResponse } from 'angular';
import { HttpService } from './http.service';
import { BlockBlobClient } from '@azure/storage-blob';
import { IStimulusProgress, IVideoLink } from './mux.service';
import * as _ from 'lodash';
import { ICreateStimulusResponse } from '../contracts/discussion.contract';
import { ServerConstants } from '../serverconstants';

export class FileStorageService {
  static $inject = [
    'httpservice',
    '$window',
    'serverConstants',
  ];

  private _stimuliUploads: IStimulusProgress[] = [];

  constructor(
    private httpService: HttpService,
    private $window: ng.IWindowService,
    private serverConstants: ServerConstants,
  ) {
    this.$window.addEventListener('beforeunload', (ev) => {
      // If at least one upload is in progress, prevent browser from closing the tab
      if (this._stimuliUploads && _.find(this._stimuliUploads, (s) => s.progress !== 100)) {
        // Cancel the event
        ev.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        ev.returnValue = '';
      }
    });
  }

  public async GetNewUploadLinkForPhoto(mimeType: string, isCommunication = false): Promise<IHttpResponse<IPhotoUploadData>> {
    return this.httpService.post<IPhotoUploadData>({
      url: `/ConversationService/GetNewUploadLinkForPhoto?mimeType=${encodeURIComponent(mimeType)}&isCommunication=${encodeURIComponent(isCommunication)}`,
    });
  }

  public async GetNewUploadLinkForVideo(mimeType: string, isCommunication = false): Promise<IHttpResponse<IVideoLink>> {
    return this.httpService.post<IVideoLink>({
      url: `/ConversationService/GetNewUploadLinkForVideo?mimeType=${encodeURIComponent(mimeType)}&isCommunication=${encodeURIComponent(isCommunication)}`,
    });
  }

  public async GetNewUploadLinkForAttachment(mimeType: string): Promise<IHttpResponse<IAttachmentUploadData>> {
    return this.httpService.post<IAttachmentUploadData>({
      url: `/ConversationService/GetNewUploadLinkForAttachment?mimeType=${encodeURIComponent(mimeType)}`,
    });
  }

  public async uploadAttachment(attachmentUploadData: IAttachmentUploadData, attachment: File) {
    const blobClient = new BlockBlobClient(attachmentUploadData.AttachmentSasUrl);
    blobClient.uploadData(attachment, {
      blobHTTPHeaders: {
        blobContentType: attachment.type,
      },
    });
  }

  public async uploadImage(photoUploadData: IPhotoUploadData, image: File) {
    this._stimuliUploads.push({
      stimulusValue: photoUploadData.StimuliGuid,
      progress: 0,
    });

    const blobClient = new BlockBlobClient(photoUploadData.PhotoSasUrl);
    blobClient.uploadData(image, {
      onProgress: (ev) => {
        const progress = Math.round(100 * ev.loadedBytes / image.size);
        const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === photoUploadData.StimuliGuid)[0];
        if (stimulusUpload) {
          stimulusUpload.progress = progress;
        }
      },
      blobHTTPHeaders: {
        blobContentType: image.type,
      },
    });
  }

  public async uploadVideo(videoUploadData: IVideoLink, video: File) {
    // if video link does not contain StimuliGuid, we will move Id to StimuliGuid and set Id to null
    if (!videoUploadData.StimuliGuid) {
      videoUploadData.StimuliGuid = videoUploadData.Id;
      delete videoUploadData.Id;
    }
    this._stimuliUploads.push({
      stimulusValue: videoUploadData.StimuliGuid,
      progress: 0,
    });

    const blobClient = new BlockBlobClient(videoUploadData.Url);
    const uploadResponse = await blobClient.uploadData(video, {
      onProgress: (ev) => {
        const progress = Math.round(100 * ev.loadedBytes / video.size);
        const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === videoUploadData.StimuliGuid)[0];
        if (stimulusUpload) {
          stimulusUpload.progress = progress;
        }
      },
      blobHTTPHeaders: {
        blobContentType: video.type,
      },
      // find a way to send metadata when uploading AMS videos (when it's implemented)
    });
    if (uploadResponse?._response?.status >= 200
      && uploadResponse?._response?.status < 300) {
      // If the upload was successful
      await this.httpService.post({
        url: '/ConversationService/StartEncodingForVideo',
        data: videoUploadData,
      });
    }
  }

  public async uploadBlobStimulus(createStimulusResponse: ICreateStimulusResponse, stimulus: File, isDiscussionNew: boolean) {
    const blobClient = new BlockBlobClient(createStimulusResponse.uploadUrl);
    blobClient.uploadData(stimulus, {
      blobHTTPHeaders: {
        blobContentType: stimulus.type,
      },
      // we need to include metadata in order to know whether to update the URL and Thumbnail URL in the monolith or in the discussions module
      metadata: {
        [this.serverConstants.storageConstantsConstants.clientCodeMetadata]: createStimulusResponse.clientCode,
        [this.serverConstants.storageConstantsConstants.isDiscussionNewMetadata]: isDiscussionNew.toString(),
      },
    });
  }

  public getStimulusUploadProgress(stimulusValue: string) {
    const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === stimulusValue)[0];
    return stimulusUpload ? stimulusUpload.progress : null;
  }

  public removeStimulusFromUploadingList(stimulusValue: string): void {
    // Remove stimulus from the list with stimuli upload in progress
    const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === stimulusValue)[0];
    if (stimulusUpload) {
      this._stimuliUploads = this._stimuliUploads.filter((s) => s.stimulusValue !== stimulusValue);
    }
  }
}

export interface IPhotoUploadData{
  PhotoSasUrl: string;
  StimuliGuid: string;
}

export interface IAttachmentUploadData{
  AttachmentSasUrl: string;
  AttachmentGuid: string;
}
