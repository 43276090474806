'use strict';

import { ActivitySpecifyComponent } from './activitySpecify.component';

angular
  .module('insitesApp.activityconfig')
  .component('activitySpecify', ActivitySpecifyComponent);

import './activityConfirmitId';
import './checkmarketId';
import './activityCredits';
import './activityInlineSurveyLink';
import './activityRewards';
import './activityStartEndDate';
import './activityTheme';
import './activityCompleteRedirect';
import './activityDecipherId';
import './activityInstinctSurveyId';
