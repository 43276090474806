'use strict';

import { NotificationsService } from './../../core/services/notifications';
import { ServerConstants } from '../../core/serverconstants';
import { ActivityService } from '../../core/dataservices/activity.service';
import { IPrepareSurveyFinished } from '../../core/contracts/activity.contract';
import { SpinnerService } from '../../core/services/spinner.service';
import _ = require('lodash');
import { FeatureService } from '../../core/dataservices/feature.service';

export class ActivityConfigController {
  static $inject = ['$stateParams', 'spinnerservice', 'activityservice', 'serverConstants', '$state', '$location', 'notifications', 'featureservice', '$q'];
  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private spinnerservice: SpinnerService,
    private activityservice: ActivityService,
    private serverConstants: ServerConstants,
    private $state: ng.ui.IStateService,
    private $location: ng.ILocationService,
    private notifications: NotificationsService,
    private featureService: FeatureService,
    private $q: ng.IQService,
  ) {
    this.squareActivityFormatConstants = serverConstants.squareActivityFormatConstants;
    this.validationConstants = serverConstants.validationConstants;
  }

  private surveyPreparationStatusSubscription = _.noop;

  initial = {};
  activity = {};
  squareActivity;
  isPublished;
  surveyPreparationStatus: number;
  isReadOnly;
  loading = true;
  squareActivityFormatConstants;
  validationConstants;
  activityTypes;
  isSavingUpReward: boolean;
  isConsentFeatureEnabled: boolean;
  backgroundVariablesDescription: string;
  recruitmentVariableLabel = 'partner';

  async $onInit() {
    this.spinnerservice.show('loading');
    this.activityTypes = this.serverConstants.squareActivityTypeConstants;
    this.loadSpecifications().then((response) => {
      this.squareActivity = response[0].data;
      this.squareActivity.isChildActivity =
        this.squareActivity.Detail.SequenceRole === this.serverConstants.activitySequenceRoleConstants.child;
      this.squareActivity.isParentActivity =
        this.squareActivity.Detail.SequenceRole === this.serverConstants.activitySequenceRoleConstants.parent;
      this.isSavingUpReward = response[1];
      this.isConsentFeatureEnabled = response[2];
      if (this.isConsentFeatureEnabled) {
        this.recruitmentVariableLabel = 'source';
      }
      let platform;
      if (this.squareActivity.Detail.ActivityType === this.activityTypes.decipherSurvey ||
        this.squareActivity.Detail.ActivityType === this.activityTypes.decipherDiarySurvey) {
        platform = 'Decipher';
      } else if (this.squareActivity.Detail.ActivityType === this.activityTypes.instinctSurvey) {
        platform = 'Instinct';
      } else if(this.squareActivity.Detail.ActivityType === this.activityTypes.confirmitSurvey) {
        platform = 'Confirmit';
      }
      this.backgroundVariablesDescription = `Choose segmentations to add to ${platform} (e.g routings, populate dataset, ...)`;
    }).finally(() => {
      this.spinnerservice.hide('loading');
      this.loading = false;
    });
    this.loadActivity().then((response) => {
      this.isPublished = response.data.IsPublished;
      this.surveyPreparationStatus = response.data.SurveyPreparationStatus;
      this.isReadOnly = false;
    });
    await this.notifications.addUserToGroup(this.$stateParams.activityGuid);
    this.surveyPreparationStatusSubscription = this.notifications
      .prepareSurveyFinished
      .subscribe((event: IPrepareSurveyFinished) => {
        if (this.$stateParams.activityGuid === event.ActivityGuid) {
          this.surveyPreparationStatus = event.IsFinished
            ? this.serverConstants.surveyPreparationStatusConstants.finished
            : this.serverConstants.surveyPreparationStatusConstants.notStarted;
        }
      });
  }

  loadSpecifications = () => this.$q.all([
    this.activityservice.selectActivityQuantResearch(this.$stateParams.activityGuid),
    this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards),
    this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.consentMapping),
  ]);

  loadActivity = () => (this.activityservice.getActivityDetails(this.$stateParams.activityGuid));

  goBackAct = () => {
    if (this.$stateParams.referer) {
      this.$location.url(this.$stateParams.referer);
    } else {
      this.$state.go('root.square.activities.quant', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
      });
    }
  };
}
