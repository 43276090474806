'use strict';

import { LabelFactory } from './label.factory';
import { ServerConstants } from './serverconstants';
import { ActivityHelperService } from './services/activityHelper.service';


export class ConstantsFactory {
  static $inject = ['labelFactory', 'serverConstants', 'activityHelperService'];
  constructor(
    private labelFactory: LabelFactory,
    private serverConstants: ServerConstants,
    private activityHelperService: ActivityHelperService) { }

  Labels() {
    return this.labelFactory.getLabelsLanguage('en');
  }

  async getLabelValue(name) {
    return (await this.Labels()).getLabelValue(name);
  }

  GetLabelForSquareActivityType(activityType, format?) {
    let formatLabel = '';
    if(format){
      const label = this.activityHelperService.getSquareActivityFormatAsString(format);
      formatLabel = `${label.charAt(0).toUpperCase()}${label.slice(1)} survey`;
    }
    switch (activityType) {
      case this.serverConstants.squareActivityTypeConstants.confirmitSurvey: {
        return `${formatLabel} (Confirmit)`;
      }
      case this.serverConstants.squareActivityTypeConstants.qualitativeResearch: {
        return 'Discussion (old)';
      }
      case this.serverConstants.squareActivityTypeConstants.checkMarketSurvey: {
        return `${formatLabel} (CheckMarket)`;
      }
      case this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch: {
        return 'Forum Discussion';
      }
      case this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch: {
        return 'Private Discussion';
      }
      case this.serverConstants.squareActivityTypeConstants.decipherSurvey: {
        return `${formatLabel} (Decipher)`;
      }
      case this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey: {
        return `${formatLabel} (Decipher Diary)`;
      }
      case this.serverConstants.squareActivityTypeConstants.publicScoutResearch: {
        return 'Life Snapshots (Group) (BETA)';
      }
      case this.serverConstants.squareActivityTypeConstants.privateScoutResearch: {
        return 'Life Scenes (Private) (BETA)';
      }
      case this.serverConstants.squareActivityTypeConstants.discussion20Research: {
        return 'Discussion';
      }
      case this.serverConstants.squareActivityTypeConstants.observation: {
        return 'Observation';
      }
      case this.serverConstants.squareActivityTypeConstants.instinctSurvey: {
        return `${formatLabel} (Instinct)`;
      }
    }
  }

  getSuspendReasonsForSelection(suspendReason): { label: string, needsComment: boolean } {
    switch (suspendReason) {
      case this.serverConstants.suspendReasonConstants.suspendedOther:
        return { label: 'Suspended: Other', needsComment: true };
      case this.serverConstants.suspendReasonConstants.suspendedPanelParticipant:
        return { label: 'Suspended: Panel Participant', needsComment: false };
      case this.serverConstants.suspendReasonConstants.suspendedLowQuality:
        return { label: 'Suspended: Low Quality', needsComment: false };
      case this.serverConstants.suspendReasonConstants.blackListedFraudulent:
        return { label: 'Blacklisted: Fraudulent', needsComment: false };
      case this.serverConstants.suspendReasonConstants.blacklistedSpam:
        return { label: 'Blacklisted: Spam', needsComment: false };
      case this.serverConstants.suspendReasonConstants.blacklistedBot:
        return { label: 'Blacklisted: Bot', needsComment: false };
      case this.serverConstants.suspendReasonConstants.blacklistedOther:
        return { label: 'Blacklisted: Other', needsComment: true };
      case this.serverConstants.suspendReasonConstants.rightToBeForgotten:
        return { label: 'Suspended: Right to be Forgotten', needsComment: false };
      case this.serverConstants.suspendReasonConstants.sourceConsentExpiration:
        return { label: 'Suspended: Source consent expiration', needsComment: false };
      case this.serverConstants.suspendReasonConstants.switchingLanguage:
        return { label: 'Suspended: Switching language', needsComment: false };
    }
  }

  getLabelForManuallyHandledRewardStatus(status: number) {
    switch (status) {
      case this.serverConstants.manuallyHandledRewardStatusConstants.handled:
        return 'Handled';
      case this.serverConstants.manuallyHandledRewardStatusConstants.notHandled:
        return 'Not Handled';
    }
  }

  // This is hardcoded because we don't really have a proper way yet to see if an activity is decipher or not purely in the frontend
  isDecipherActivityType(type: number) {
    return type === this.serverConstants.squareActivityTypeConstants.decipherSurvey
      || type === this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey;
  }

}

angular
  .module('insitesApp.core')
  .service('constantsfactory', ConstantsFactory);
