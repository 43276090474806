'use strict';

import { ActivityInstinctSurveyIdController } from './activityInstinctSurveyId.controller';

const template = require('./activityInstinctSurveyId.html');
require('./activityInstinctSurveyId.scss');

export const ActivityInstinctSurveyIdComponent: ng.IComponentOptions = {
  controller: ActivityInstinctSurveyIdController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    isDisabled: '<',
  },
  require: {
    form: '^^form',
  },
};
