'use strict';

import { ServerConstants } from '../../../../core/serverconstants';
import { ActivityService } from '../../../../core/dataservices/activity.service';
import { SelectedSquareFactory } from '../../../../core/selectedsquare.factory';
import { ImageCropService } from '../../../../layout/image-crop/image-crop.service';
import { IStimuliIgnoreFilter } from '../../../../core/filters/stimuli-ignore.filter';
import { ParentActivityChangeConfirm } from '../../../common/ParentActivityChangeConfirm';
import * as _ from 'lodash';
import { ActivityProbeQualPresentModel } from './activityProbeQualPresentModel';
import { SquareActivityModel } from '../../squareActivityModel';
import { PoboService } from '../../../../core/services/pobo.service';
import { ConfigurationService } from '../../../../core/dataservices/configuration.service';
import { IFileConfigurationResponse } from '../../../../core/contracts/configuration.contracts';
import { ForumService } from '../../../../core/dataservices/forum.service';

import { DiscussionService } from '../../../../core/dataservices/discussion.service';
import { DiscussionDataService } from '../../../../core/services/discussionData.service';
import { MappingService } from '../../../../core/services/mapping.service';
import { CommunicationService } from '../../../../core/dataservices/communication.service';
import { IscUIUtils } from 'isc-ui';
import { FeatureService } from '../../../../core/dataservices/feature.service';

export class ActivityProbeQualPresentController extends ParentActivityChangeConfirm {
  static $inject = ['serverConstants', 'labelFactory', 'selectedSquareFactory', 'activityservice',
    '$stateParams', 'logger', 'imageCropService', 'stimuliIgnoreFilter', '$mdDialog', '$scope',
    'poboService', 'iscConfigurationService', 'forumservice', 'mappingService',
    'discussionService','discussionDataService', 'communicationservice', 'featureservice'];

  wizardStep;
  removeLink: () => void;
  validationConstants: ServerConstants['validationConstants'];
  communicationChannelConstants: ServerConstants['communicationChannelConstants'];
  form: ng.IFormController;
  squareActivity: SquareActivityModel;
  invalidCommunicationFiles = [];
  isValid = false;
  saveNotApplicable = false;
  saveCallback = () => this.updateActivityPresent(this);
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  originalShowOnHomepage: boolean;
  originalSticky: boolean;
  isPublished: boolean;
  model = new ActivityProbeQualPresentModel();
  isReadOnly: boolean;
  resetFormCallback = () => this.resetForm();
  imageStimuliMaxSizeBytes: number;
  videoStimuliMaxSizeBytes: number;
  fileTypeConstants;
  fileConfiguration: IFileConfigurationResponse[] = [];
  acceptedMimeTypes: string[];
  acceptedMimeTypesString: string;
  acceptedPatterns: string;
  acceptedExtensions: string;
  isCurationAndFeddbackFeatureEnabled: boolean;

  constructor(
    private serverConstants: ServerConstants,
    private labelFactory,
    private selectedSquareFactory: SelectedSquareFactory,
    private activityservice: ActivityService,
    private $stateParams: ng.ui.IStateParamsService,
    private logger: Logger,
    private imageCropService: ImageCropService,
    private stimuliIgnoreFilter: IStimuliIgnoreFilter,
    protected $mdDialog: ng.material.IDialogService,
    private $scope: ng.IScope,
    private poboService: PoboService,
    private iscConfigurationService: ConfigurationService,
    private forumservice: ForumService,
    protected mappingService: MappingService,
    protected discussionService: DiscussionService,
    protected discussionDataService: DiscussionDataService,
    protected communicationservice: CommunicationService,
    private featureService: FeatureService,

  ) {
    super($mdDialog);
    this.validationConstants = serverConstants.validationConstants;
    this.communicationChannelConstants = serverConstants.communicationChannelConstants;
    featureService.checkFeatureAccessibilityForSetup(serverConstants.featureConstants.curationFeedbackCircle)
      .then((featureEnabled) => this.isCurationAndFeddbackFeatureEnabled = featureEnabled);
  }

  $onInit() {
    this.fileTypeConstants = this.iscConfigurationService.getFileTypes();
    this.iscConfigurationService.getFileConfiguration()
      .then((response) => {
        this.fileConfiguration = response;
        this.imageStimuliMaxSizeBytes = _.find(this.fileConfiguration,
          (config) => config.FileType === this.fileTypeConstants.image).MaxFileSizeMb * Math.pow(1024, 2);
        this.videoStimuliMaxSizeBytes = _.find(this.fileConfiguration,
          (config) => config.FileType === this.fileTypeConstants.video).MaxFileSizeMb * Math.pow(1024, 2);

        const { mimeTypes, patterns, extensions } = this.iscConfigurationService
          .getAcceptedMimeTypesAndExtensions(this.fileConfiguration, [this.fileTypeConstants.image, this.fileTypeConstants.video]);
        this.acceptedMimeTypes = mimeTypes;
        this.acceptedMimeTypesString = mimeTypes.toString();
        this.acceptedPatterns = `'${patterns.toString()}'`;
        this.acceptedExtensions = extensions.toString();
      });

    this.$loadData(this.squareActivity);
    this.removeLink = this.wizardStep.linkComponent('presentQual', this);
  }

  async $wizardStepLoaded() {
    // Load default fields
    if (!this.model.CallToActionText) {
      const squareLanguage = await this.selectedSquareFactory.languagePromise;
      const labels = await this.labelFactory.getLabelsLanguage(squareLanguage);
      this.model.CallToActionText = labels.getLabelValue('LabelCallToActionTextDefaultResearchActivity');
    }
  }

  $loadData(data, isSavingCurrentStep: boolean = false) {
    this.model = new ActivityProbeQualPresentModel();

    if(!data || !data.ActivityQualDetail) {
      return;
    }

    const qualData = data.ActivityQualDetail;
    this.model.CallToActionText = qualData.CallToActionText;
    this.model.CardTeaser = qualData.CardTeaser;
    this.model.CommunicationGuid = qualData.CommunicationGuid;
    this.model.ShowOnPublicPage = false;
    // If this step isn't complete yet the values shouldn't be taken over from the object in the db.
    // The default values of these booleans should be used in place.
    if (this.$wizardStepIsCompleted()) {
      this.model.ShowOnHomepage = qualData.ShowOnHomepage;
      this.model.ShowOnPublicPage = qualData.ShowOnPublicPage;
      this.originalShowOnHomepage = qualData.ShowOnHomepage;
      this.model.Sticky = qualData.Sticky;
      this.originalSticky = qualData.Sticky;
    }

    // If we save the present step just use the image that was just
    // manually cropped instead of immediatly using the blob url
    if (data.CommunicationStimuli != null) {
      const stimuliSource = isSavingCurrentStep
        ? this.serverConstants.communicationConstants.stimuliSourceUpload
        : this.serverConstants.communicationConstants.stimuliSourceDb;

      this.model.CommunicationStimuli.push({
        source: stimuliSource,
        action: this.serverConstants.communicationConstants.stimuliActionIgnore,
        imgUrl: data.CommunicationStimuli.ThumbnailUrl,
        type: data.CommunicationStimuli.FileType,
        guid: data.CommunicationStimuli.Guid,
      });
    }

    this.saveNotApplicable = this.squareActivity.ActivityQualDetail.IsPublished;
  }

  removeCommunicationFile(file) {
    this.invalidCommunicationFiles = [];
    const index = this.model.CommunicationStimuli.indexOf(file);
    if (this.model.CommunicationStimuli[index].source === this.serverConstants.communicationConstants.stimuliSourceUpload) {
      this.model.CommunicationStimuli.splice(index, 1);
    } else {
      this.model.CommunicationStimuli[index].action = this.serverConstants.communicationConstants.stimuliActionDelete;
    }
    if (file.imgUrl) {
      const fileIndex = this.model.CommunicationFiles.map((f) => f.name).indexOf(file.imgUrl.name);
      if (fileIndex >= 0) {
        this.model.CommunicationFiles.splice(fileIndex, 1);
      }
    }
  }

  isImageFile(file) {
    let result = false;
    if (file && file.type && this.acceptedMimeTypes && this.acceptedMimeTypes.length) {
      const imageMimeTypes = this.acceptedMimeTypes.filter((mt) => mt.indexOf('image') === 0);
      result = imageMimeTypes.indexOf(file.type) > -1;
    }
    return result;
  }

  isVideoFile(file) {
    let result = false;
    if (file && file.type && this.acceptedMimeTypes && this.acceptedMimeTypes.length) {
      const videoMimeTypes = this.acceptedMimeTypes.filter((mt) => mt.indexOf('video') === 0);
      result = videoMimeTypes.indexOf(file.type) > -1;
    }
    return result;
  }

  async validateCommunicationFiles($files, $newFiles, $invalidFiles) {
    if (!$newFiles) {
      return;
    }

    for (let file of $newFiles) {
      _.pull($files, file);
      this.invalidCommunicationFiles = [];
      const isImageFile = this.isImageFile(file);

      if ((isImageFile && file.size > this.imageStimuliMaxSizeBytes) ||
        (this.isVideoFile(file) && file.size > this.videoStimuliMaxSizeBytes)) {
        file.$errorMessages = file.$errorMessages || {};
        file.$errorMessages.maxSize = true;
        this.invalidCommunicationFiles.push(file);
        $invalidFiles.splice(0);
      } else if (file.$errorMessages) {
        this.invalidCommunicationFiles.push(file);
        $invalidFiles.splice(0);
      } else if (this.isCommunicationStimuliLimitReached()) {
        this.invalidCommunicationFiles.push(file);
        file.$errorMessages = { maxFiles: true };
      } else {
        if (isImageFile) {
          try {
            file = await this.imageCropService.cropStimuli(file);
          } catch (err) {
            // User canceled
            continue;
          }
        }
        this.model.CommunicationStimuli.push({
          source: this.serverConstants.communicationConstants.stimuliSourceUpload,
          imgUrl: file,
          action: this.serverConstants.communicationConstants.stimuliActionUpload,
          type: file.type,
        });
        this.model.CommunicationFiles.push(file);
        this.$scope.$evalAsync();
      }
    }
  }

  private isCommunicationStimuliLimitReached(): boolean {
    return this.stimuliIgnoreFilter(this.model.CommunicationStimuli).length > 0;
  }

  resetForm() {
    this.form.$setPristine();
    this.$loadData(this.squareActivity);
  }

  getVideoStimulusDisplaySrc(file): string {
    return (typeof file.imgUrl === 'string') ? file.imgUrl : 'images/video-placeholder.png';
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardStepIsCompleted() {
    return this.model.CardTeaser != null && this.model.CardTeaser !== '';
  }

  $wizardIsValid() {
    if (this.form !== undefined) {
      this.isValid = true;
      const stimuli = this.model.CommunicationStimuli.filter((s) =>
        s.action !== this.serverConstants.communicationConstants.stimuliActionDelete);

      return this.form.$valid && (this.model.CommunicationFiles.length > 0 || stimuli.length > 0);
    }
  }

  $wizardNextLabel() {
    return this.isReadOnly ? 'Continue' : 'Save and continue';
  }

  $wizardNextDescription() {
    let description = '';
    if (this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft) {
      description = this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }
    return description;
  }

  async $wizardBeforeNext() {
    if (this.squareActivity.ActivityQualDetail?.IsPublished === true) {
      const username = await this.poboService.checkPoboConfirmation(
        this.squareActivity.ActivityQualDetail.OpenPostUsername);
      this.squareActivity.ActivityQualDetail.OpenPostUsername = username;
    }
    await this.updateActivityPresent(this);
  }

  // Returns true when changes for the childActivities must be made
  protected hasChanges(): boolean {
    return this.model.ShowOnHomepage !== this.originalShowOnHomepage || this.model.Sticky !== this.originalSticky;
  }

  async updateActivityPresent(root: this) {
    if (this.squareActivity && this.squareActivity.isParentActivity) {
      await this.showParentActivityChangeConfirm();
    }
    const datacommunication = {
      ActivityGuid: root.$stateParams.activityGuid,
      Title: root.squareActivity.ActivityQualDetail.Title,
      Message: root.model.CardTeaser,
      Sticky: root.model.Sticky,
      StartDateTime: root.squareActivity.Detail.StartDate,
      CallToActionText: root.model.CallToActionText,
      StimuliCollection: root.model.CommunicationStimuli,
      Stimuli: [],
      ShowOnHomepage: root.model.ShowOnHomepage,
      ShowOnPublicPage: root.model.ShowOnPublicPage,
      IsQualActivity: true,
    };
    const stimuliAndFiles = await this.forumservice.prepareStimuliAndNewStimuli(
      root.model.CommunicationStimuli.map((stimulus) => ({
        file: stimulus.imgUrl,
        isPreview: false,
        status: 1,
        type: stimulus.type,
      })), true);
    datacommunication.StimuliCollection.forEach((stimulus, index) => {
      const prepared = stimuliAndFiles.stimuli[index];
      stimulus.id = prepared.id;
      stimulus.url = prepared.url;
      stimulus.thumbnailUrl = prepared.thumbnailUrl;
    });
    stimuliAndFiles.files.forEach((f) => datacommunication.Stimuli.push(stimuliAndFiles.files.indexOf(f)));
    const communicationData = await root.activityservice.createUpdateActivityConfigCommunication(
      datacommunication, stimuliAndFiles.files);
    const wizardTargeting = await root.wizardStep.resolveComponent('targeting');
    wizardTargeting.communicationGuid = communicationData.CommunicationGuid;
    if (root.$stateParams.activityType && !IscUIUtils.isActivityDiscussionNewType(root.$stateParams.activityType, root.serverConstants)) {
      const result = await root.activityservice.selectActivityQualResearch(root.$stateParams.activityGuid);
      root.squareActivity = result.data;

    } else {
      const communication = await root.communicationservice.getCommunicationWithStimuliForActivityQual(root.$stateParams.activityGuid);
      this.mappingService.mapCommunicationDetailsToSquareActivityModel(communication, this.squareActivity);

      root.squareActivity.isChildActivity =
        root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.child;
      root.squareActivity.isParentActivity =
        root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.parent;
    }
    root.$loadData(root.squareActivity, true);
    root.form.$setPristine();
    root.logger.success('Activity present successfully updated');
  }
}
