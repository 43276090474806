'use strict';

import { IImpersonate, IClaims, TokenForSquareResponse } from './dataservice.contracts';
import { HttpService } from './http.service';
import { Utils } from '../utils';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/toPromise';
import { CurrentUserService } from './currentUser.service';
import { ParticipantService } from './participant.service';
import { ServerConstants } from '../serverconstants';
import { SquareService } from './square.service';
import { SelectedSquareFactory } from '../selectedsquare.factory';
import { IHttpPromise } from 'angular';
import { CacheService } from '../services/cache.service';
import _ = require('lodash');

export class AuthService {
  static $inject = ['httpservice', 'serverConstants', '$stateParams',
    '__env', '$rootScope', '$q', 'currentUserService', '$state', 'squareservice', 'participantservice', 'selectedSquareFactory'];
  constructor(
    private httpservice: HttpService,
    private serverConstants: ServerConstants,
    private $stateParams: ng.ui.IStateParamsService,
    private __env: Environment,
    private $rootScope: ng.IRootScopeService,
    private $q: ng.IQService,
    private currentUserService: CurrentUserService,
    private $state: ng.ui.IStateService,
    private squareservice: SquareService,
    private participantService: ParticipantService,
    private selectedSquareFactory: SelectedSquareFactory,
  ) {

    $rootScope.$on('redirectToSquareLogin', async () => {
      const redirectUrl = await this.getSquareLogoutUrl();
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    });
  }

  private readonly JWT_KEY = 'SW5TaXRlc1NxdWFyZV9KV1Q=';
  private memberGuid: string;
  private _memberGuid = new ReplaySubject<string>(1);
  private _impersonate: IImpersonate;
  private isLoggingOut: boolean;
  private tokenInfo;

  init() {
    if (this.isAuthorized()) {
      const token = this.getToken();
      const claims = this.getClaims();
      this.httpservice.useToken(token);
      this.currentUserService.setUserProfileFromClaims(claims);
      this.currentUserService.onImpersonateAction(this.impersonate);
    }
  }

  get impersonate(): IImpersonate {
    // first of all, check member _impersonate object
    if (this._impersonate === undefined) {
      // then check local storage and fallback to session storage
      const jsImpersonate = window.localStorage.getItem('Impersonating') || window.sessionStorage.getItem('Impersonating');
      // setup member _impersonate object
      this._impersonate = !jsImpersonate ? null : JSON.parse(jsImpersonate);
      // validate if impersonating for current square or not
      if (this._impersonate && this._impersonate.SquareGuid) {
        if (this._impersonate.SquareGuid !== this.squareGuid) {
          this._impersonate = null;
          return null;
        }
      }
      this.httpservice.useImpersonate(this._impersonate);
    }
    return this._impersonate;
  }

  set impersonate(_impersonate: IImpersonate) {
    if (!_impersonate) {
      this._impersonate = null;
      window.localStorage.removeItem('Impersonating');
      window.sessionStorage.removeItem('Impersonating');
    } else {
      window.localStorage.setItem('Impersonating', JSON.stringify(_impersonate));
      // add a fallback for when impersonating is removed in an other tab
      window.sessionStorage.setItem('Impersonating', JSON.stringify(_impersonate));
      this._impersonate = _impersonate;
    }
    this.httpservice.useImpersonate(this._impersonate);
    if (!this.isLoggingOut) {
      this.currentUserService.onImpersonateAction(_impersonate);
    }
  }

  get isImpersonating(): boolean {
    return this._impersonate
      && this._impersonate.SquareParticipantGuid
      && this._impersonate.SquareParticipantGuid !== '';
  }

  get memberGuidObservable() {
    return this._memberGuid.asObservable();
  }

  get uniqueMemberName() {
    const claims = this.getClaims();
    return claims.uniqueName;
  }

  isDeveloper(): boolean {
    const claims = this.getClaims();
    return claims.isDeveloper;
  }

  isSquareOnly(): boolean {
    const claims = this.getClaims();
    return claims.isSquareOnly;
  }

  isTeamLead(): boolean {
    const claims = this.getClaims();
    return claims.isTeamLead;
  }

  hasFullAccess(): boolean {
    const claims = this.getClaims();
    return claims.hasFullAccess;
  }

  isFullDev(): boolean {
    const claims = this.getClaims();
    return claims.isFullDev;
  }

  hasUserDoItYourselfAccess(): boolean {
    const claims = this.getClaims();
    return claims.hasUserDoItYourselfAccess;
  }

  get firstName() {
    const claims = this.getClaims();
    return claims.firstname;
  }

  get userName(): string {
    const claims = this.getClaims();
    return claims.username;
  }

  async setJwToken(token, shouldLoadTokenInfo: boolean = true) {
    CacheService.setCache({ key: this.JWT_KEY, value: token });
    this.httpservice.useToken(token);
    if (shouldLoadTokenInfo) {
      this.getTokenInfo.cache = new _.memoize.Cache();
      await this.loadTokenInfo();
      this.httpservice.useToken(token);
    }
  }

  async ensureTokenInfoLoaded(): Promise<void> {
    if (this.tokenInfo) {
      return this.$q.resolve(this.tokenInfo);
    }
    this.setJwToken(this.getToken());
    await this.loadTokenInfo();
  }

  async setTokenForSquare(clientGuid, squareGuid): Promise<TokenForSquareResponse> {
    let response;
    this.getTokenInfo.cache = new _.memoize.Cache();
    if (squareGuid && squareGuid !== this.getClaims().squareGuid) {
      try {
        response = await this.getTokenForSquare(clientGuid, squareGuid);
        await this.loadTokenInfo();
      } catch (error) {
        await this.redirectToHomeRouteInfoForLoggedInUser();
      }
    }
    if (squareGuid || this.isObserverUserLogin()) {
      await this.setSquareUserProfile();
    } else {
      const claims = this.getClaims();
      this.currentUserService.setUserProfileFromClaims(claims);
      this.impersonate = undefined;
    }
    return response;
  }

  async setTokenForClient(clientGuid): Promise<void> {
    if (clientGuid && clientGuid !== this.getClaims().clientGuid) {
      try {
        await this.getTokenForClient(clientGuid);
        this.getTokenInfo.cache = new _.memoize.Cache();
        await this.loadTokenInfo();
        const claims = this.getClaims();
        this.currentUserService.setUserProfileFromClaims(claims);
        this.impersonate = undefined;
      } catch (error) {
        await this.redirectToHomeRouteInfoForLoggedInUser();
      }
    }
  }

  removeToken() {
    CacheService.removeCacheValue(this.JWT_KEY);
  }

  updateUser(): IHttpPromise<any> {
    if (this.isAuthorized()) {
      const token = this.getToken();
      this.httpservice.useToken(token);

      const promise = this.loadTokenInfo();
      promise.then((): IHttpPromise<any> => {
        if (this.isHuman8UserLogin()) {
          this.currentUserService.setUserProfileFromClaims(this.getClaims());
          return this.httpservice.post({
            url: '/AuthorizationService/UpdateUser',
          });
        }
      });
      return promise;
    }
  }

  async getSquareTokenForInSitesUser(squareGuid: string, clientGuid: string) {
    await this.httpservice.post({
      url: '/AuthorizationService/CreateSquareParticipantForInSitesUser',
      params: {
        squareGuid,
      },
      headers: {
        ClientGuid: clientGuid,
        SquareGuid: squareGuid,
      },
    });
  }

  private async setSquareUserProfile() {
    const userClaims = this.getClaims();
    // memberGuid can be undefined, thus, condition properly
    if (userClaims.memberGuid && userClaims.memberGuid !== '') {
      await this.currentUserService.setUserProfileFromSquareParticipantGuid(userClaims.memberGuid);
      return true;
    }
    // when not in a square context, there is no memberGuid
    // so we are going to use the claims
    this.currentUserService.setUserProfileFromClaims(userClaims);
    this.impersonate = undefined;
    return false;
  }

  isHuman8UserLogin(): boolean {
    const userRole = this.getRole();
    return userRole === this.getRoleLabel(this.serverConstants.roleConstants.human8);
  }

  isObserverUserLogin(): boolean {
    const userRole = this.getRole();
    return userRole === this.getRoleLabel(this.serverConstants.roleConstants.observer);
  }

  hasUserRole(roles: number[]): boolean {
    const roleLabels = roles.map((role) => this.getRoleLabel(role));
    return roleLabels.includes(this.getRole());
  }

  isModeratorUserLogin(): boolean {
    const userRole = this.getRole();
    const professionalAdminRole = this.getRoleLabel(this.serverConstants.roleConstants.professionalAdmin);
    const clientAdminRole = this.getRoleLabel(this.serverConstants.roleConstants.clientAdmin);
    const editorRole = this.getRoleLabel(this.serverConstants.roleConstants.clientEditor);
    return userRole === professionalAdminRole || userRole === clientAdminRole || userRole === editorRole;
  }

  async redirectToHomeRouteInfoForLoggedInUser(
    switchInterface: boolean = false,
    activateMessage: boolean = false) {
    const redirectRouteInfo = await this.getHomeRouteInfoForLoggedInUser(switchInterface, activateMessage);
    if (!activateMessage) {
      delete redirectRouteInfo.routeData.activate;
    }

    this.$state.go(redirectRouteInfo.routeName, redirectRouteInfo.routeData);
  }

  async redirectToRoute(redirectType: number = 0, switchInterface: boolean = false, additionalParams) {
    switch (redirectType) {
      case this.serverConstants.researcherLoginRedirectTypeConstants.activities:
        await this.redirectToHomeRouteInfoForLoggedInUser(switchInterface, additionalParams.activateMessage);
        break;
      case this.serverConstants.researcherLoginRedirectTypeConstants.activityWizard:
        this.$state.go('root.square.activityWizardType', {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
          activityGuid: additionalParams.activityGuid,
          step: additionalParams.step,
        });
        break;
      case this.serverConstants.researcherLoginRedirectTypeConstants.activityConversation: {
        this.$state.go('root.square.activitydata', {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
          activityGuid: additionalParams.activityGuid,
          replyGuid: additionalParams.replyGuid,
          isEdit: 'true',
        });
      }
    }
  }

  async getHomeRouteInfoForLoggedInUser(
    switchInterface: boolean = false,
    activateMessage: boolean = false,
    clientGuid: string = null,
    programGuid: string = null,
    squareGuid: string = null): Promise<{ routeName, routeData }> {
    const activitiesRoute = (activateMsg: boolean): { routeName: string, routeData: Record<string, unknown> } => {
      const userClaims = this.getClaims();
      return {
        routeName: 'root.square.activities.all',
        routeData: {
          clientGuid: clientGuid ?? userClaims.clientGuid,
          programGuid: programGuid ?? userClaims.programGuid,
          squareGuid: squareGuid ?? userClaims.squareGuid,
          activate: activateMsg,
        },
      };
    };

    if (activateMessage) {
      await this.redirectToActivationPage();
      return;
    }

    if (switchInterface) {
      return activitiesRoute(activateMessage);
    }

    await this.ensureTokenInfoLoaded();

    if (this.__env.showStartPage) {
      this.$state.go('root.landing', {}, { reload: true });
      return;
    }

    switch (this.getRole()) {
      case this.getRoleLabel(this.serverConstants.roleConstants.observer):
      {
        const userClaims = this.getClaims();
        return {
          routeName: 'root.observerlandingpage',
          routeData: {
            clientGuid: clientGuid ?? userClaims.clientGuid,
            activate: activateMessage,
          },
        };
      }
      case this.getRoleLabel(this.serverConstants.roleConstants.professionalAdmin):
      case this.getRoleLabel(this.serverConstants.roleConstants.clientAdmin):
      case this.getRoleLabel(this.serverConstants.roleConstants.clientEditor):
      {
        return activitiesRoute(activateMessage);
      }
      default: {
        return {
          routeName: '',
          routeData: '',
        };
      }
    }
  }

  getToken(): string {
    return CacheService.getCacheValue(this.JWT_KEY);
  }

  getOrRenewSignalRToken(): ng.IPromise<string> {
    return this.httpservice.get({
      url: '/AuthorizationService/GetTokenForSignalR',
    }).then((response) => response.data);
  }

  public async getTokenForSquare(clientGuid, squareGuid): Promise<TokenForSquareResponse> {
    return this.httpservice.get({
      url: '/AuthorizationService/GetTokenForSquare',
      params: {
        clientGuid,
        squareGuid,
      },
    }).then((response) => response.data);
  }

  private async getTokenForClient(clientGuid): Promise<string> {
    return this.httpservice.get({
      url: '/AuthorizationService/GetTokenForClient',
      params: {
        clientGuid,
      },
    }).then((response) => response.data);
  }

  getRole(): string {
    const userClaims = this.getClaims();
    return this.getRoleLabel(userClaims.role);
  }

  getMemberGuid(): string {
    return this.memberGuid;
  }

  public get SquareParticipantGuid() {
    const userClaims = this.getClaims();
    return userClaims.memberGuid;
  }

  async getSquareLogoutUrl(subDomain = null): Promise<string> {
    if (subDomain) {
      return `//${subDomain}.${this.__env.squareUrlBase}/logout`;
    } else if (this.$stateParams.squareGuid) {
      const squareInfoResponse: any = await this.getCurrentSquareInfo();
      return `//${squareInfoResponse.data.Detail.Subdomain}.${this.__env.squareUrlBase}/logout`;
    }

    // If squareGuid is empty then the token is expired -> remove the token and redirect to home (a login page will be shown for a Human8 user)
    this.removeToken();
    return this.__env.interfaceUrl;
  }

  private getClaims(): IClaims {
    if (!this.tokenInfo || !this.getToken()) {
      return {
        role: 0,
        adObjectId: undefined,
        squareGuid: undefined,
        programGuid: undefined,
        clientGuid: undefined,
        memberGuid: undefined,
        firstname: '',
        lastname: '',
        username: '',
        uniqueName: '',
        isSquareOnly: false,
        isTeamLead: false,
        isDeveloper: false,
        hasFullAccess: false,
        hasUserDoItYourselfAccess: false,
        isFullDev: false,
      } as IClaims;
    }

    return {
      role: this.tokenInfo.Role,
      adObjectId: this.tokenInfo.adObjectId,
      squareGuid: this.tokenInfo.SquareGuid,
      programGuid: this.tokenInfo.ProgramGuid,
      clientGuid: this.tokenInfo.ClientGuid,
      memberGuid: this.tokenInfo.SquareParticipantGuid,
      firstname: this.tokenInfo.FirstName,
      lastname: this.tokenInfo.FamilyName,
      username: this.tokenInfo.Username,
      uniqueName: this.tokenInfo.UniqueName,
      isSquareOnly: this.tokenInfo.IsSquareOnly,
      isTeamLead: this.tokenInfo.IsTeamLead,
      isDeveloper: this.tokenInfo.IsDeveloper,
      hasFullAccess: this.tokenInfo.HasFullAccess,
      hasUserDoItYourselfAccess: this.tokenInfo.HasUserDoItYourselfAccess,
      isFullDev: this.tokenInfo.IsFullDev,
    } as IClaims;
  }

  private extractSegmentFromUrl(guidName): string {
    const part = window.location.pathname.split(`${guidName}/`)[1];
    return part?.split('/')[0] ?? null;
  }

  get clientGuid(): string {
    if (window.location.pathname && window.location.pathname.match('client')) {
      return this.extractSegmentFromUrl('client');
    }

    return this.selectedSquareFactory.clientGuid;
  }

  get squareGuid(): string {
    if (window.location.pathname && window.location.pathname.match('square')) {
      return this.extractSegmentFromUrl('square');
    }

    return this.selectedSquareFactory.Guid;
  }

  private getTokenInfo = _.memoize(() => this.httpservice.get({
    headers: { ClientGuid: this.clientGuid, SquareGuid: this.squareGuid },
    url: '/AuthorizationService/GetTokenInfo',
  }));

  private loadTokenInfo(): IHttpPromise<any> {
    const promise = this.getTokenInfo();
    promise.then((response) => {
      const info = response.data;
      info.SquareGuid = info.SquareGuid === '00000000-0000-0000-0000-000000000000' ? undefined : info.SquareGuid;
      info.ProgramGuid = info.ProgramGuid === '00000000-0000-0000-0000-000000000000' ? undefined : info.ProgramGuid;
      info.ClientGuid = info.ClientGuid === '00000000-0000-0000-0000-000000000000' ? undefined : info.ClientGuid;
      info.SquareParticipantGuid = info.SquareParticipantGuid === '00000000-0000-0000-0000-000000000000' ? undefined : info.SquareParticipantGuid;
      this.tokenInfo = info;
    });
    return promise;
  }

  private getRoleLabel(role: number): string {
    return Utils.getEnumValueName(this.serverConstants.roleConstants, role);
  }

  private getCurrentSquareInfo() {
    return this.httpservice.getAnonymous({
      url: '/ProjectService/SquareInfo',
    });
  }

  async logout(logOutOnAllSquares = true) {
    this.isLoggingOut = true;
    this.getTokenInfo.cache = new _.memoize.Cache();

    if (logOutOnAllSquares) {
      await this.logOutOnAllSquares();
      this.removeToken();
      this.impersonate = undefined;
      window.localStorage.removeItem('Impersonating');
      window.sessionStorage.removeItem('Impersonating');
    }
    // Check if Role is Human8
    if (this.isHuman8UserLogin() || this.__env.useSsoLoginPage) {
      this.removeToken();
      this.$rootScope.$emit('notAuthorized', true);
    } else {
      let redirectUrl;

      if (this.isObserverUserLogin() || this.isModeratorUserLogin()) {
        // If the user was on squarelevel at the moment of the logout, redirect to the login of that square via the logout route
        if (this.$stateParams.squareGuid) {
          redirectUrl = await this.getSquareLogoutUrl();
        } else {
          // Since we don't have the square guid here it is hard to find wich square the user came from (if they have multiple)
          // Moderators with multiple squares are redirected to corp website
          const squareList = await this.squareservice.getSquareListForObserverOrModerator();
          if (squareList.length > 1) {
            redirectUrl = this.serverConstants.squareConstants.corporateWebsite;
            // When we don't know the square to go to, logout of all squares too
            await this.logOutOnAllSquares();
            window.localStorage.removeItem('Impersonating');
            window.sessionStorage.removeItem('Impersonating');
          } else {
            // Moderators or observers with one square are redirected to the login page of that square via the logout route
            redirectUrl = await this.getSquareLogoutUrl(squareList[0].Url);
          }
        }
      }

      this.removeToken();
      this.impersonate = undefined;
      window.location.href = redirectUrl;
    }
  }

  async logOutOnAllSquares() {
    return this.httpservice.post({
      url: '/ParticipantService/LogoutOnAllSquares',
    });
  }

  validateToken() {
    // Makes the simplest api call to be sure the token is still valid
    // Or it will give unauthorized response so relogin will be triggered
    return this.httpservice.get({
      url: '/AuthorizationService/GetTokenInfo',
    });
  }

  async hasInSitesUserAccess(clientGuid, squareGuid = null): Promise<boolean> {
    return this.httpservice.get({
      url: '/AuthorizationService/HasInSitesUserAccess',
      params: { ClientGuid: clientGuid, SquareGuid: squareGuid },
    }).then((response) => response.data);
  }

  isAuthorized = _.memoize(() => CacheService.getCacheValue(this.JWT_KEY) != null);

  async redirectToActivationPage() {
    const apiResponse = await this.participantService.getAdObjectIdForCurrentUser();
    this.$state.go('root.activation', {
      objectId: apiResponse.adObjectId,
    });
  }

  async getTokenFor(url?: string, clientCode?: string, squareGuid?: string): Promise<boolean> {
    return this.httpservice.get({
      url: '/AuthorizationService/GetTokenFor',
      params: { url, clientCode, squareGuid },
    }).then((response) => response.data);
  }
}
