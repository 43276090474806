'use strict';

import { CommunicationService } from './../../../../../core/dataservices/communication.service';
import { FeatureService } from './../../../../../core/dataservices/feature.service';
import { ActivityService } from './../../../../../core/dataservices/activity.service';
import { DateFormatService } from './../../../../../core/services/dateformat.service';
import { DateTime } from 'luxon';
import { ActivitySpecifyControllerBase } from '../../../../common/ActivitySpecifyControllerBase';
import { ServerConstants } from '../../../../../core/serverconstants';
import * as _ from 'lodash';
import { SquareService } from '../../../../../core/dataservices/square.service';
import { ISquareAppScreenerInfo } from '../../../../../core/dataservices/square.service.contracts';

export class ActivitySpecifyController extends ActivitySpecifyControllerBase {
  static $inject = ['activityservice', 'communicationservice', '$stateParams',
    'logger', 'dateFormatService', '$q', 'serverConstants', '$scope', '$mdDialog',
    'featureservice','squareservice'];

  constructor(
    private activityservice: ActivityService,
    private communicationservice: CommunicationService,
    private $stateParams,
    private logger: Logger,
    dateFormatService: DateFormatService,
    private $q: ng.IQService,
    serverConstants: ServerConstants,
    private $scope,
    $mdDialog,
    private featureService: FeatureService,
    private squareservice: SquareService,
  ) {
    super($mdDialog, dateFormatService, serverConstants);
  }

  activityType: number;
  activityTypes;
  removeLink;
  wizardStep;
  form: ng.IFormController;
  squareActivityFormatConstants = this.serverConstants.squareActivityFormatConstants;
  showLink = false;
  currentTimeZone = DateTime.local().offset / 60;
  showThemes = false;
  showLengthOfInterview = false;
  showCredits = false;
  showRewards = false;
  showCompleteRedirect = false;
  allowStartDateEdit = false;
  allowEndDateEdit = false;
  allowCreditsEdit = false;
  allowRewardsEdit = false;
  allowExecutionDateEdit = false;
  allowIdEdit = false; // Confirmit, Decipher or checkmarket ID
  squareActivity;
  isEditMode = false;
  statusses;
  isPublished;
  isReadOnly;
  isSavingUpReward: boolean;
  saveNotApplicable = false;
  saveCallback = () => this.saveChanges(this);
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  resetFormCallback = () => this.resetForm();
  isActivityTargetCompletionEnabled;
  linkedAppScreener: ISquareAppScreenerInfo;
  wizard;

  model = {
    AllowStartDateEdit: false,
    SurveyId: '',
    StartDateTime: DateTime.now(),
    EndDateTime: DateTime.now(),
    Credits: '',
    CompletionPoints: null, // Saving up Reward
    ExecDateTime: DateTime.now(),
    DateOffset: this.currentTimeZone,
    SquareNames: [],
    Rewards: [],
    DateLastSynced: '',
    Theme: '',
    InlineSurveyUri: '',
    Format: 0,
    Status: 0,
    ActivityCompleteRedirectGuid: '',
    IsCompletionTarget: false,
    CompletionTarget: null,
    SquareJobId: '',
    IsAppScreener: null,
    UseSubsource: false,
  };

  async $onInit() {
    this.activityTypes = this.serverConstants.squareActivityTypeConstants;

    this.removeLink = this.wizardStep.linkComponent('specify', this);
    this.initComponentsToShow(this.squareActivity.Detail.Format);
    this.loadData(this.squareActivity);

    this.allowStartDateEdit = this.model.AllowStartDateEdit;
    this.allowEndDateEdit = (this.model.Rewards.length >= 0 || this.model.Format !== this.serverConstants.squareActivityFormatConstants.research);
    this.allowCreditsEdit = this.allowRewardsEdit =
      this.model.Status === this.serverConstants.squareActivityStatusConstants.scheduled
      || this.model.Status === this.serverConstants.squareActivityStatusConstants.draft;
    this.allowExecutionDateEdit = this.allowEndDateEdit && !this.isReadOnly && this.model.Rewards.length > 0;
    this.allowIdEdit = this.model.Status === this.serverConstants.squareActivityStatusConstants.draft ||
      !this.squareActivity.Detail.SurveyId || this.squareActivity.Detail.SurveyId === '';

    this.isActivityTargetCompletionEnabled = await this.featureService.checkFeatureAccessibilityForSetup(
      this.serverConstants.featureConstants.activityTargetCompletion);

    this.$scope.$watch('vm.isPublished', () => {
      this.saveNotApplicable = this.isPublished;
    });

    this.$scope.$watch('vm.model.Rewards.length', () => {
      this.allowExecutionDateEdit = this.allowEndDateEdit && !this.isReadOnly && this.model.Rewards.length > 0;
    });

    this.saveNotApplicable = this.isPublished;
    if (this.model.Format === this.squareActivityFormatConstants.screener) {
      await this.refreshAppScreenerInfo();
    }
  }

  initComponentsToShow(activityFormat) {
    // Note: some are shown by default
    switch (activityFormat) {
      case this.squareActivityFormatConstants.research:
        this.showLengthOfInterview = true;
        this.showCredits =
          this.showRewards =
          this.showCompleteRedirect =
          this.squareActivity.Detail.ActivityType === this.activityTypes.confirmitSurvey ||
          this.squareActivity.Detail.ActivityType === this.activityTypes.decipherSurvey ||
          this.squareActivity.Detail.ActivityType === this.activityTypes.instinctSurvey;
        this.showThemes = true;

        if (this.isSavingUpReward) {
          if (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey) {
            this.showCredits = true;
          }
        }
        break;
      case this.squareActivityFormatConstants.profile:
        this.showLink =
          this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.confirmitSurvey;
        this.showLengthOfInterview = true;
        this.showCredits = true;
        break;
      case this.squareActivityFormatConstants.screener:
        break;
      default: break;
    }
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    let description = '';
    if (this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft) {
      description = this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }
    return description;
  }

  $wizardStepIsCompleted() {
    // Screener + research
    // It's possible that the next step will check before squareActivity is loaded, in which case we can check the model instead
    return this.squareActivity?.Detail.SurveyId !== null || this.model.SurveyId !== null;
  }

  $wizardIsValid() {
    // Make CompletionTarget empty when the checkbox is unticked
    this.model.CompletionTarget = this.model.IsCompletionTarget ? this.model.CompletionTarget : null;
    if (!this.form.$valid) {
      return false;
    }

    const lastRewardOption = this.model.Rewards.slice(-1)[0];
    if (lastRewardOption && (!angular.isNumber(lastRewardOption.Amount) || !angular.isNumber(lastRewardOption.Value) || !lastRewardOption.CurrencyGuid) &&
      (angular.isNumber(lastRewardOption.Amount) || angular.isNumber(lastRewardOption.Value) || lastRewardOption.CurrencyGuid)) {
      return false;
    }

    if (this.validateCompletionTarget() && !this.model.CompletionTarget) {
      return false;
    }

    if (this.isSavingUpReward
      && this.model.CompletionPoints
      && (this.model.CompletionPoints.Qualified < 0
        || this.model.CompletionPoints.Qualified > this.serverConstants.validationConstants.integerMaxValue
        || this.model.CompletionPoints.QuotaFull < 0
        || this.model.CompletionPoints.QuotaFull > this.serverConstants.validationConstants.integerMaxValue
        || this.model.CompletionPoints.ScreenedOut < 0
        || this.model.CompletionPoints.ScreenedOut > this.serverConstants.validationConstants.integerMaxValue)) {
      return false;
    }

    return this.form.$valid;
  }

  async refreshAppScreenerInfo(reset : boolean = false) {
    const appScreenerInfo = await this.squareservice.getSquareAppScreenerInfo(reset);
    this.linkedAppScreener = appScreenerInfo;
  }

  validateCompletionTarget() {
    return !this.isActivityTargetCompletionEnabled && this.model.IsCompletionTarget;
  }

  getRewardList() {
    if (this.model.Rewards.length === 1 && !this.model.Rewards[0].Amount && !this.model.Rewards[0].Value && !this.model.Rewards[0].CurrencyGuid) {
      return [];
    }
    return this.model.Rewards;
  }

  resetForm() {
    this.form.$setPristine();
    this.loadData(this.squareActivity);
  }

  confirmChangeAppScreener() {
    const promise = this.model.IsAppScreener && this.linkedAppScreener.LinkedScreenerGuid &&
      this.linkedAppScreener.LinkedScreenerGuid !== this.squareActivity.Detail.Guid ?
      this.$mdDialog.show( // Show confirm dialog
        this.$mdDialog.iscConfirm()
          .title('Switch Mobile App screener')
          .text('Only 1 active screener can be used for mobile app registration. By confirming this change this screener will be used for app registration. ' +
            `It will be unchecked for screener '${this.linkedAppScreener.LinkedScreenerName}'`)
          .ok('Switch screener')
          .cancel('Do not switch screener')) :
      this.$q.resolve();
    return promise;
  }

  async saveData() {
    return this.confirmChangeAppScreener()
      .then(
        () => {} // Do nothing when confirmed
        ,() => {
          this.model.IsAppScreener = false;
        })
      .finally(async () => {
        const rewards = this.getRewardList();
        const data = {
          Guid: this.$stateParams.activityGuid,
          SurveyId: this.model.SurveyId,
          Credits: this.model.Credits,
          CompletionPoints: this.model.CompletionPoints,
          StartDate: this.dateFormatService.getDateTimeForSaving(this.model.StartDateTime, this.model.DateOffset),
          EndDate: this.dateFormatService.getDateTimeForSaving(this.model.EndDateTime, this.model.DateOffset),
          RaffleExecutionDate: rewards.length > 0 ? (this.model.ExecDateTime ?
            this.dateFormatService.getDateTimeForSaving(this.model.ExecDateTime, this.model.DateOffset) : null) : null,
          Offset: this.model.DateOffset,
          Rewards: rewards,
          InlineSurveyUri: this.model.InlineSurveyUri,
          ThemeGuid: this.model.Theme,
          ActivityCompleteRedirectGuid: this.model.ActivityCompleteRedirectGuid,
          CompletionTarget: this.model.IsCompletionTarget ? this.model.CompletionTarget : null,
          IsAppScreener: this.model.IsAppScreener,
        };
        try {
          await this.activityservice.updateActivityConfig(data);
          this.squareActivity.Detail.StartDate = data.StartDate;
          if (this.model.Format === this.squareActivityFormatConstants.screener) {
            this.wizard.steps[1].component.getActivityRecruitmentPartners();
            this.refreshAppScreenerInfo(true);
          }
          if (this.model.Format === this.squareActivityFormatConstants.profile) {
            const communicationData = await this.communicationservice.getOrSetCommunicationProfileActivity({ activityGuid: this.$stateParams.activityGuid });
            const wizardTargeting = await this.wizardStep.resolveComponent('targeting');
            wizardTargeting.communicationGuid = communicationData.CommunicationGuid;
            wizardTargeting.isReadOnly = this.isReadOnly;
          }
          // Update squareActivity because the only check for completed needs SurveyUri
          await this.activityservice.selectActivityQuantResearch(this.$stateParams.activityGuid).then((response) => {
            this.squareActivity = response.data;
          });
        } catch (error) {
          if (error.status === 400) {
            const errorData = error.data;
            // Group by property name in case there is more than 1 error for that property
            // Ideally we should already group them in the backend
            const grouped = _.groupBy(errorData.ValidationErrors, 'PropertyName');
            _.forEach(grouped, (item, key) => {
              let message = '';
              _.forEach(item, (errorMessage: any) => {
                message += `${errorMessage.ErrorMessage} `;
              });
              this.form[key].$setDirty();
              this.form[key].$setValidity('serverErrors', false);
              this.form[key].errorMessage = message;
              this.form[key].$setTouched();
            });
          }
          return this.$q.reject(error);
        }
        if (!this.isReadOnly) {
          this.logger.success('Activity specifications saved');
        }
        this.form.$setPristine();
      });
  }

  loadData(result) {
    const data = result.Detail;
    this.model.AllowStartDateEdit = data.CanChangeStartDate;
    this.model.SurveyId = data.SurveyId;
    this.model.Theme = data.ThemeGuid;
    this.model.Credits = data.Credits === 0 ? '' : data.Credits;
    this.model.DateOffset = data.Offset ? data.Offset : this.currentTimeZone;
    this.model.StartDateTime = this.dateFormatService.getDateTime(data.StartDate, this.model.DateOffset);
    this.model.EndDateTime = data.EndDate ? this.dateFormatService.getDateTime(data.EndDate, this.model.DateOffset) : DateTime.now().plus({ days: 7 });
    this.model.ExecDateTime = data.RaffleExecutionDate
      ? this.dateFormatService.getDateTime(data.RaffleExecutionDate, this.model.DateOffset)
      : data.Status !== this.serverConstants.squareActivityStatusConstants.closed ? this.model.EndDateTime.plus({ hours: 1 }) : undefined;
    this.model.SquareNames = this.splitString(data.SquareNames);
    this.model.Rewards = result.RaffleDetailItems === undefined ? this.model.Rewards = [] : result.RaffleDetailItems;
    this.model.Format = data.Format;
    this.model.Status = data.Status ? data.Status : this.serverConstants.squareActivityStatusConstants.draft;
    this.model.InlineSurveyUri = data.InlineSurveyUri;
    this.model.DateLastSynced = data.DateLastSynced;
    this.model.ActivityCompleteRedirectGuid = data.ActivityCompleteRedirectGuid;
    this.isEditMode = data.StartDate === null;
    this.model.SquareJobId = data.SquareJobId;
    if (this.isSavingUpReward) {
      this.model.CompletionPoints = {
        Qualified: data.CompletionPoints,
        ScreenedOut: data.CompletionPointsScreenedOut,
        QuotaFull: data.CompletionPointsQuotaFull,
      };
    }
    this.model.IsCompletionTarget = data.IsCompletionTarget;
    this.model.CompletionTarget = data.CompletionTarget;
    this.model.IsAppScreener = data.IsAppScreener;
  }

  splitString(data) {
    return data ? data.split(',') : [];
  }

  saveChanges(root) {
    const rewards = root.getRewardList();
    const data = {
      Guid: root.$stateParams.activityGuid,
      SurveyId: root.model.SurveyId,
      ThemeGuid: root.model.Theme,
      Credits: root.model.Credits,
      CompletionPoints: root.model.CompletionPoints,
      StartDate: root.dateFormatService.getDateTimeForSaving(root.model.StartDateTime, root.model.DateOffset),
      EndDate: root.dateFormatService.getDateTimeForSaving(root.model.EndDateTime, root.model.DateOffset),
      RaffleExecutionDate: rewards.length > 0 ? (root.model.ExecDateTime ?
        root.dateFormatService.getDateTimeForSaving(root.model.ExecDateTime, root.model.DateOffset) : null) : null,
      Offset: root.model.DateOffset,
      Rewards: rewards,
      InlineSurveyUri: root.model.InlineSurveyUri,
      CompletionTarget: root.model.IsCompletionTarget ? root.model.CompletionTarget : null,
    };
    return root.activityservice.updateActivityConfig(data)
      .then(() => {
        root.isEditMode = false;
        root.logger.success('Activity specifications saved');
        root.form.$setPristine();
      },
      (error) => {
        root.logger.error('Activity specifications could not be saved');
        return root.$q.reject(error);
      });
  }

  protected getSquareActivity() {
    return this.squareActivity;
  }

  protected getModel() {
    return this.model;
  }

  protected isReadOnlyActivity(): boolean {
    return this.isReadOnly;
  }
}
